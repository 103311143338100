import ImagesPanel from '../ImagesPanel/ImagesPanel'
import ObjectsPanel from '../ObjectsPanel/ObjectsPanel'
import BorderTextPanel from "../BorderTextPanel/BorderTextPanel";
import TemplatesPanel from '../TemplatesPanel/TemplatesPanel'
import LayersPanel from '../LayersPanel/LayersPanel'
import TextPanel from '../TextPanel/TextPanel'
import { Scrollbars } from 'react-custom-scrollbars'
import classNames from 'classnames'
import './PanelItem.scss'


interface Props {
  panelOpen: boolean
  activeTab: string
}
function PanelItem({ panelOpen, activeTab }: Props) {
  const className = classNames({
    'panel-item-container': true,
    open: panelOpen,
  })

  return (
    <div className={className}>
      <div className="panel-item">
        <Scrollbars
          renderThumbVertical={() => <div style={{ background: 'rgba(255,255,255,0.3)' }}></div>}
          autoHide
        >
          {activeTab === 'templates' && <TemplatesPanel />}
          {activeTab === 'images' && <ImagesPanel />}
          {activeTab === 'backgroundimages' && <ImagesPanel />}
          {activeTab === 'objects' && <ObjectsPanel />}
          {activeTab === 'borderText' && <BorderTextPanel />}
          {activeTab === 'text' && <TextPanel />}
          {activeTab === 'layers' && <LayersPanel />}
        </Scrollbars>
      </div>
    </div>
  )
}

export default PanelItem
