import { Input, InputGroup, InputLeftElement } from '@chakra-ui/react'
import { SearchIcon } from '@chakra-ui/icons'
import { useCoreHandler } from '@/components/Canvas/handlers'

function LayersPanel() {
  const { addObject } = useCoreHandler()
  const addHeading = () => {
    const options = {
      type: 'text',
      text: '큰이름',
      fontSize: 55,
      width: 300,
      fontWeight: 500,
      // fontFamily: 'Gothic A1',
      fontFamily: 'Roboto Medium',
      textAlign: 'center',
    }
    addObject(options)
  }

  const addSubheading = () => {
    const options = {
      type: 'text',
      text: '작은이름',
      fontSize: 36,
      width: 320,
      fontWeight: 800,
      fontFamily: 'Lexend',
      textAlign: 'center',
    }
    addObject(options)
  }

  const addTextBody = () => {
    const options = {
      type: 'text',
      text: '배지에 대한 설명',
      fontSize: 24,
      width: 320,
      fontWeight: 300,
      fontFamily: 'Lexend',
      textAlign: 'center',
    }
    addObject(options)
  }
  return (
    <>
      <div className="panel-text" style={{ padding: '1rem 1.5rem' }}>
        <div className="label">추가할 텍스트 타입을 입력하세요</div>
        <div className="add-text-items">
          <div onClick={addHeading} className="add-text-item add-heading">
            큰이름
          </div>
          <div onClick={addSubheading} className="add-text-item add-subheading">
            작은이름
          </div>
          <div onClick={addTextBody} className="add-text-item add-body-text">
            배지에 대한 설명
          </div>
        </div>
      </div>
    </>
  )
}
export default LayersPanel
