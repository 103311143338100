export function GithubIcon() {
  return (
    <div className="navbar-icon">
      <svg fill="currentColor" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 .297c-6.63 0-12 5.373-12 12 0 5.303 3.438 9.8 8.205 11.385.6.113.82-.258.82-.577 0-.285-.01-1.04-.015-2.04-3.338.724-4.042-1.61-4.042-1.61C4.422 18.07 3.633 17.7 3.633 17.7c-1.087-.744.084-.729.084-.729 1.205.084 1.838 1.236 1.838 1.236 1.07 1.835 2.809 1.305 3.495.998.108-.776.417-1.305.76-1.605-2.665-.3-5.466-1.332-5.466-5.93 0-1.31.465-2.38 1.235-3.22-.135-.303-.54-1.523.105-3.176 0 0 1.005-.322 3.3 1.23.96-.267 1.98-.399 3-.405 1.02.006 2.04.138 3 .405 2.28-1.552 3.285-1.23 3.285-1.23.645 1.653.24 2.873.12 3.176.765.84 1.23 1.91 1.23 3.22 0 4.61-2.805 5.625-5.475 5.92.42.36.81 1.096.81 2.22 0 1.606-.015 2.896-.015 3.286 0 .315.21.69.825.57C20.565 22.092 24 17.592 24 12.297c0-6.627-5.373-12-12-12" />
      </svg>
    </div>
  )
}

export function DownloadSVGIcon() {
  return (
    <div className="navbar-icon">
      <svg xmlns="http://www.w3.org/2000/svg" width="80" height="24" viewBox="0 0 24 24">
          <text dx="100%" x="-220%" y="70%" fill="#9900EF">SVG</text>
        <path
          fill="#9900EF"
          x="220%"
          d="M11.25 15.85l-4.38-4.38a.75.75 0 0 0-1.06 1.06l4.95 4.95c.69.68 1.8.68 2.48 0l4.95-4.95a.75.75 0 1 0-1.06-1.06l-4.38 4.38V4.25a.75.75 0 1 0-1.5 0v11.6zm-7.5 3.4h16.5a.75.75 0 1 1 0 1.5H3.75a.75.75 0 1 1 0-1.5z"
        ></path>
      </svg>
    </div>
  )
}

export function DownloadPNGIcon() {
  return (
    <div className="navbar-icon">
      <svg xmlns="http://www.w3.org/2000/svg" width="80" height="24" viewBox="0 0 24 24">
          <text dx="100%" x="-220%" y="70%" fill="#9900EF">PNG</text>
        <path
          fill="#9900EF"
          x="220%"
          d="M11.25 15.85l-4.38-4.38a.75.75 0 0 0-1.06 1.06l4.95 4.95c.69.68 1.8.68 2.48 0l4.95-4.95a.75.75 0 1 0-1.06-1.06l-4.38 4.38V4.25a.75.75 0 1 0-1.5 0v11.6zm-7.5 3.4h16.5a.75.75 0 1 1 0 1.5H3.75a.75.75 0 1 1 0-1.5z"
        ></path>
      </svg>
    </div>
  )
}

// export function LogoIcon() {
//   return (
//     <svg version="1.1" id="dxworks" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100%"
//          viewBox="0 0 739 169">
//       <path fill="currentColor" opacity="1.000000" stroke="none"
//             d="
// M493.000000,170.000000
// 	C328.711731,170.000000 164.923492,170.000000 1.067614,170.000000
// 	C1.067614,113.727310 1.067614,57.454521 1.067614,1.090863
// 	C247.333313,1.090863 493.666656,1.090863 739.965820,1.090863
// 	C739.965820,57.409882 739.965820,113.704956 739.965820,170.000000
// 	C657.787781,170.000000 575.643921,170.000000 493.000000,170.000000
// M86.740479,158.241470
// 	C88.533401,157.639084 90.326324,157.036697 92.871590,156.348038
// 	C95.278275,155.335541 97.684959,154.323059 100.777916,153.127228
// 	C103.300980,151.482010 105.824043,149.836792 108.985703,148.014954
// 	C113.128319,143.725784 117.270935,139.436600 121.937271,134.761932
// 	C139.666595,109.231644 138.917145,72.509132 119.990906,47.975361
// 	C116.437248,44.607273 112.883591,41.239185 108.934959,37.292660
// 	C105.289581,35.234673 101.644196,33.176685 97.409882,30.696758
// 	C94.609657,29.784893 91.809441,28.873028 88.431320,27.479494
// 	C85.620407,27.016775 82.809494,26.554058 79.182907,25.883699
// 	C77.511009,25.735590 75.839111,25.587482 73.375725,25.218233
// 	C69.924858,25.263626 66.473984,25.309019 62.140266,25.171061
// 	C59.893250,25.171061 57.646229,25.171061 55.332569,25.171061
// 	C55.332569,36.820496 55.332569,47.829601 55.332569,58.979160
// 	C58.091095,58.979160 60.547028,58.979160 63.904964,59.138145
// 	C67.242233,59.220833 70.579498,59.303520 74.495384,59.757420
// 	C75.870422,59.926121 77.245461,60.094826 79.149269,60.727909
// 	C81.672340,61.874554 84.195412,63.021202 87.091415,64.733383
// 	C89.933647,67.879730 92.775879,71.026085 95.857849,74.888031
// 	C100.640160,85.300888 100.893448,95.943001 97.120499,107.339333
// 	C94.988220,110.846794 92.855942,114.354256 90.156219,118.166664
// 	C88.438789,119.547363 86.721367,120.928062 84.357384,122.500267
// 	C81.843414,123.475983 79.329445,124.451706 75.930817,125.523598
// 	C72.955650,125.786583 69.980484,126.049568 66.094490,126.143761
// 	C62.522285,126.143761 58.950077,126.143761 55.360386,126.143761
// 	C55.360386,137.803619 55.360386,148.798798 55.360386,159.959290
// 	C59.454315,159.959290 63.229050,159.959290 67.909187,160.117096
// 	C71.269226,159.982742 74.629265,159.848389 78.744934,159.822495
// 	C81.163536,159.260406 83.582146,158.698318 86.740479,158.241470
// M234.739594,116.372795
// 	C225.012268,103.658615 215.284943,90.944435 205.363281,77.605606
// 	C195.586121,64.733070 185.808975,51.860538 175.838058,38.337154
// 	C173.444916,35.150265 170.843704,32.094845 168.721786,28.736576
// 	C166.890762,25.838701 164.614685,24.988230 161.292816,25.061512
// 	C151.993958,25.266647 142.687622,25.133617 133.384247,25.133924
// 	C129.341797,25.134058 125.299332,25.133947 120.047134,25.133947
// 	C138.030334,48.556522 155.191849,70.908882 172.689316,93.907639
// 	C176.705170,97.479614 176.444031,101.160454 172.385727,105.260353
// 	C159.053085,123.237213 145.720444,141.214081 131.863113,159.898392
// 	C143.139328,159.898392 153.276962,159.960449 163.411819,159.820892
// 	C164.697937,159.803192 166.380325,159.063538 167.173782,158.085892
// 	C169.992233,154.613281 172.505310,150.892822 175.718460,146.897293
// 	C181.831161,138.858246 187.943878,130.819183 194.186981,122.608658
// 	C197.416504,126.634392 200.110931,129.993118 202.999420,133.984818
// 	C207.718552,140.332062 212.973175,146.359879 217.004044,153.117661
// 	C220.923126,159.688080 226.001343,161.583786 233.919968,160.268326
// 	C244.837601,160.268326 255.755234,160.268326 267.885742,160.268326
// 	C256.438782,145.253952 245.677002,131.138336 234.739594,116.372795
// M332.475525,127.046616
// 	C334.770233,119.458092 337.064941,111.869576 339.359650,104.281059
// 	C339.682495,104.313034 340.005310,104.345016 340.328125,104.376999
// 	C345.753998,122.594864 351.179840,140.812729 356.615417,159.063293
// 	C360.757294,159.063293 364.467499,158.677078 368.062225,159.156525
// 	C373.081085,159.825882 374.795929,157.607727 376.052917,153.048965
// 	C380.688721,136.236420 385.796722,119.554306 390.699341,102.814980
// 	C393.351074,93.760948 395.946381,84.690384 398.657288,75.315437
// 	C391.820282,75.315437 385.560944,75.315437 379.203918,75.315437
// 	C374.360504,94.844391 369.561523,114.194084 364.762543,133.543777
// 	C364.502869,133.592865 364.243164,133.641953 363.983490,133.691055
// 	C358.704803,114.224846 353.426117,94.758636 348.154480,75.318573
// 	C342.323914,75.318573 336.874573,75.318573 331.320557,75.318573
// 	C326.075134,94.686493 320.877289,113.878746 315.408142,134.072617
// 	C314.677612,132.123398 314.194214,131.134460 313.932343,130.089981
// 	C309.648651,113.003731 305.450867,95.895432 301.026642,78.845833
// 	C300.652039,77.402145 298.876160,75.361008 297.630005,75.270699
// 	C292.194702,74.876801 286.714569,75.101372 280.705780,75.101372
// 	C289.018677,103.390991 297.187469,131.190155 305.335815,158.919769
// 	C311.472748,158.919769 317.113403,158.919769 322.979919,158.919769
// 	C326.066040,148.501968 329.131744,138.153030 332.475525,127.046616
// M557.992188,120.466789
// 	C571.240967,104.207947 564.020081,80.017090 543.780396,76.592644
// 	C529.547668,74.184555 514.744446,75.148132 500.154541,74.604637
// 	C500.154541,103.388046 500.154541,131.088348 500.154541,158.844604
// 	C506.051819,158.844604 511.645355,158.844604 517.858276,158.844604
// 	C517.858276,148.943497 517.858276,139.239319 517.858276,129.236343
// 	C520.942688,129.236343 523.456848,129.521469 525.879700,129.172653
// 	C529.994385,128.580276 532.139526,130.406403 533.940369,133.926590
// 	C537.796021,141.463425 542.000427,148.824661 546.189392,156.185318
// 	C546.844604,157.336624 548.134216,158.877609 549.190125,158.925613
// 	C555.055420,159.192230 560.939148,159.053482 567.487915,159.053482
// 	C561.175720,147.755905 555.303833,137.246414 549.536926,126.924744
// 	C552.462158,124.749062 554.983643,122.873726 557.992188,120.466789
// M423.928162,80.335388
// 	C410.957062,89.096092 406.379059,102.059959 406.273438,116.892639
// 	C406.180267,129.976990 409.568756,142.087021 420.033997,150.869141
// 	C432.502747,161.332504 446.967102,162.770508 461.809448,157.532181
// 	C477.198242,152.101013 484.673309,139.865311 486.419891,124.178955
// 	C488.326385,107.056458 485.100464,91.226082 469.832672,80.962204
// 	C455.318085,71.204659 439.844940,71.673485 423.928162,80.335388
// M640.921509,84.420448
// 	C643.316345,81.564812 645.711182,78.709175 648.737366,75.100754
// 	C642.196716,75.100754 636.739319,74.800301 631.342712,75.242485
// 	C629.050476,75.430305 626.257263,76.618996 624.767883,78.304031
// 	C616.724121,87.404396 609.020874,96.805634 601.192871,106.096977
// 	C599.443726,108.173149 597.665222,110.224693 595.900269,112.287582
// 	C595.508606,112.127342 595.117004,111.967094 594.725342,111.806854
// 	C594.725342,99.641830 594.725342,87.476814 594.725342,75.362144
// 	C588.469482,75.362144 582.875122,75.362144 577.172485,75.362144
// 	C577.172485,103.327850 577.172485,131.032135 577.172485,158.700333
// 	C583.154236,158.700333 588.863892,158.700333 594.815796,158.700333
// 	C594.815796,153.533646 595.416626,148.631927 594.685425,143.937714
// 	C593.337891,135.286636 598.295898,130.187195 603.940491,124.765717
// 	C611.299255,135.389816 618.460388,145.777344 625.699341,156.110336
// 	C626.492981,157.243240 627.702148,158.855408 628.772400,158.898102
// 	C635.287598,159.158020 641.818298,159.027847 649.083374,159.027847
// 	C637.941772,143.116760 627.378418,128.031479 616.664001,112.730408
// 	C624.681274,103.366669 632.565613,94.158226 640.921509,84.420448
// M682.660400,89.725494
// 	C694.170349,86.846428 700.064758,89.261269 705.142639,98.824615
// 	C710.671875,98.824615 716.121887,98.824615 721.654053,98.824615
// 	C721.217407,90.490532 717.572571,84.257431 711.002563,79.740547
// 	C698.668701,71.261063 676.920532,72.283783 665.449402,81.810753
// 	C652.914124,92.221420 654.605225,110.389404 668.973450,117.929268
// 	C672.461975,119.759903 676.350891,120.901489 680.149353,122.050034
// 	C686.024414,123.826424 692.039429,125.151176 697.880493,127.024628
// 	C701.707031,128.251953 704.831604,130.742554 704.741455,135.223434
// 	C704.651550,139.696091 701.852417,142.440964 697.830811,144.107224
// 	C686.634949,148.745926 676.642700,144.567993 671.663513,133.133011
// 	C666.191895,133.133011 660.730347,133.133011 655.281067,133.133011
// 	C654.931396,140.531830 657.953003,147.623718 663.331665,152.245926
// 	C675.626160,162.811264 702.381958,163.087250 714.807556,152.776917
// 	C725.478516,143.922516 725.823181,127.441292 715.079102,118.760872
// 	C711.429932,115.812653 706.865601,113.705017 702.395752,112.134682
// 	C695.992249,109.885017 689.225403,108.690025 682.743530,106.635124
// 	C679.002258,105.449051 675.740967,103.144798 675.587219,98.612137
// 	C675.437866,94.210854 677.989624,91.550186 682.660400,89.725494
// M21.956930,156.259460
// 	C21.956930,113.367470 21.956930,70.475487 21.956930,27.214172
// 	C29.841352,27.214172 36.983162,27.342764 44.113617,27.115368
// 	C45.711040,27.064426 47.268703,25.766693 48.844673,25.042957
// 	C47.338619,24.379274 45.847118,23.183907 44.324039,23.142223
// 	C36.999874,22.941774 29.663912,23.182119 22.338942,22.996929
// 	C18.840010,22.908472 17.695463,24.228365 17.701391,27.662891
// 	C17.776169,70.974419 17.793489,114.286240 17.678267,157.597580
// 	C17.667900,161.494034 19.160305,162.402893 22.694586,162.298889
// 	C29.685022,162.093170 36.693508,162.041183 43.679352,162.314148
// 	C47.758064,162.473541 49.138538,161.238586 49.115795,156.989288
// 	C48.949959,126.005676 49.027214,95.020775 49.023418,64.036278
// 	C49.023296,63.037754 49.218960,61.945976 48.880356,61.068645
// 	C48.580666,60.292141 47.681793,59.275520 47.011990,59.248646
// 	C46.369785,59.222881 45.270683,60.237686 45.101528,60.964813
// 	C44.769306,62.392910 44.912567,63.938740 44.912251,65.435867
// 	C44.906128,94.421364 44.907703,123.406868 44.907692,152.392365
// 	C44.907692,154.159805 44.907696,155.927231 44.907696,158.471924
// 	C36.839035,158.022614 29.477243,157.612656 21.956930,156.259460
// M219.999069,47.518501
// 	C224.446930,41.705006 228.855347,35.860531 233.376923,30.104946
// 	C234.248627,28.995321 235.542770,27.465464 236.677505,27.434639
// 	C245.036270,27.207581 253.404053,27.311901 262.677490,27.311901
// 	C260.822174,29.852989 259.668457,31.457819 258.489594,33.043991
// 	C246.086166,49.733128 233.645020,66.394554 221.344437,83.159111
// 	C220.476639,84.341858 220.637131,86.279099 220.319473,87.865501
// 	C221.771484,87.138916 223.726974,86.790909 224.600388,85.629288
// 	C238.895538,66.616913 253.068848,47.512753 267.205261,28.381718
// 	C268.024780,27.272640 268.295166,25.757801 268.821136,24.431799
// 	C267.486572,23.967691 266.156647,23.117619 264.816650,23.101423
// 	C255.331482,22.986765 245.842239,22.945814 236.359634,23.141184
// 	C234.788635,23.173550 232.707977,24.057711 231.769867,25.257462
// 	C222.445496,37.182198 213.267929,49.223228 204.206467,61.349174
// 	C203.468292,62.336998 203.891663,64.192825 203.775299,65.645332
// 	C204.996414,65.076729 206.613220,64.827530 207.372864,63.886429
// 	C211.550812,58.710476 215.519104,53.365295 219.999069,47.518501
// M497.321411,47.030960
// 	C501.789551,42.614712 502.832764,36.318275 499.856049,31.733007
// 	C497.446869,28.022038 489.307556,25.598225 484.937103,27.427156
// 	C484.198914,27.736080 483.270996,28.746956 483.256744,29.450241
// 	C483.127045,35.850513 483.180664,42.254498 483.180664,49.809422
// 	C488.344513,48.890575 492.490173,48.152908 497.321411,47.030960
// M521.653442,47.954174
// 	C525.285522,48.814121 523.018555,50.242523 521.711060,50.803001
// 	C520.434509,51.350224 518.773499,50.920113 517.326721,51.176060
// 	C516.149414,51.384342 515.039368,51.972591 512.674438,52.843449
// 	C523.382141,56.668415 527.376709,54.050610 527.429077,44.807755
// 	C527.450623,41.006130 527.432617,37.204281 527.432617,33.501915
// 	C519.136963,31.054443 514.243652,32.950268 513.176941,38.802219
// 	C512.225525,44.021641 514.975586,47.355923 521.653442,47.954174
// M563.792175,33.666866
// 	C561.426697,33.209927 559.071289,32.525433 556.690308,32.420567
// 	C555.866699,32.384293 554.979614,33.788208 554.121521,34.534283
// 	C555.032471,34.913155 555.930115,35.328957 556.857239,35.662868
// 	C558.569458,36.279537 560.299011,36.848202 562.021301,37.436852
// 	C561.842957,37.925156 561.664612,38.413456 561.486206,38.901760
// 	C560.675964,38.901760 559.865662,38.899811 559.055420,38.902092
// 	C555.567505,38.911903 552.515991,39.867790 552.482239,43.954311
// 	C552.445801,48.364193 555.642334,49.275669 559.340515,49.150452
// 	C560.664551,49.105625 562.109009,49.330063 563.273193,48.866188
// 	C564.244263,48.479248 565.591492,47.234543 565.555054,46.424461
// 	C565.372192,42.359879 564.789429,38.313286 563.792175,33.666866
// M671.506226,38.902477
// 	C667.552612,38.976498 664.449768,40.124298 664.959045,44.905212
// 	C665.404419,49.086769 669.736328,50.202351 678.004944,48.168167
// 	C678.004944,45.036484 678.130676,41.755150 677.975891,38.487099
// 	C677.735596,33.413750 672.520508,30.393181 667.971558,32.645752
// 	C667.386047,32.935688 667.058960,33.747517 666.612183,34.317577
// 	C667.285339,34.801254 667.902222,35.552990 668.644165,35.708683
// 	C669.901489,35.972488 671.397644,35.498215 672.504639,35.987602
// 	C674.316772,36.788757 675.504822,38.091114 671.506226,38.902477
// M625.487122,34.173962
// 	C621.753784,31.127008 617.786865,32.141010 613.556152,33.388416
// 	C613.556152,37.805122 613.402161,42.119690 613.668091,46.408222
// 	C613.731873,47.436241 615.191956,48.377678 616.009888,49.358936
// 	C616.475586,49.067989 616.941284,48.777042 617.406982,48.486099
// 	C617.406982,44.405006 617.406982,40.323917 617.406982,36.272255
// 	C621.757080,34.887642 623.335571,36.534615 623.199341,40.475334
// 	C623.130615,42.463291 622.899475,44.520836 623.314941,46.421638
// 	C623.557251,47.530190 624.931763,48.391262 625.796875,49.363689
// 	C626.368469,49.047207 626.940125,48.730728 627.511780,48.414246
// 	C626.998718,43.890198 626.485657,39.366154 625.487122,34.173962
// M609.052673,43.455017
// 	C609.046143,32.471481 605.213562,29.705616 595.386841,33.847221
// 	C595.386841,37.898918 595.205811,42.059322 595.509216,46.184086
// 	C595.588867,47.266605 597.170105,48.238674 598.061707,49.261467
// 	C598.453430,48.941109 598.845093,48.620750 599.236816,48.300392
// 	C599.236816,46.312733 599.236877,44.325073 599.236877,42.337414
// 	C599.236877,40.363880 599.236816,38.390343 599.236816,36.402851
// 	C603.245544,34.960884 605.214111,36.063869 605.105652,40.268066
// 	C605.054199,42.260983 604.821228,44.314247 605.211914,46.229771
// 	C605.456116,47.427254 606.709534,48.418938 607.510132,49.502964
// 	C607.953613,49.256424 608.397156,49.009880 608.840637,48.763336
// 	C608.911316,47.303516 608.981995,45.843700 609.052673,43.455017
// M704.784241,48.821022
// 	C709.795288,45.482407 711.219299,40.228718 708.340820,35.699364
// 	C706.116943,32.200165 701.431030,31.122232 697.849792,33.286118
// 	C694.244629,35.464470 692.905762,41.151112 695.111267,45.234608
// 	C697.047913,48.820442 700.229736,49.643761 704.784241,48.821022
// M632.939148,34.562527
// 	C628.909119,41.218567 630.940308,48.261261 637.169861,49.111317
// 	C640.800537,49.606724 643.861694,48.509827 645.341492,44.988773
// 	C646.903625,41.271915 646.926147,37.406414 643.836853,34.403187
// 	C640.667114,31.321798 637.044128,31.464067 632.939148,34.562527
// M544.522705,31.940910
// 	C544.333984,30.680635 544.145264,29.420361 543.956543,28.160084
// 	C543.527039,27.929569 543.097534,27.699055 542.668091,27.468540
// 	C541.916687,28.629572 540.588745,29.756903 540.518555,30.957752
// 	C540.276672,35.095963 540.269714,39.274063 540.557556,43.410221
// 	C540.889465,48.179504 543.948120,50.253841 548.433105,48.992161
// 	C549.190186,48.779171 549.749268,47.862114 550.399841,47.270355
// 	C549.704102,46.794022 548.959839,45.851494 548.320679,45.918114
// 	C544.581970,46.307781 544.259705,43.939457 544.243713,41.223713
// 	C544.234253,39.607742 544.242004,37.991669 544.242004,36.920692
// 	C546.613586,35.899342 548.450500,35.108246 550.287415,34.317150
// 	C550.078247,33.837158 549.869019,33.357166 549.659790,32.877171
// 	C548.183716,32.751415 546.707581,32.625656 544.522705,31.940910
// M650.509521,40.996864
// 	C651.274231,42.821178 651.886841,44.732426 652.861450,46.436718
// 	C653.432373,47.435066 654.567322,48.725266 655.472046,48.743935
// 	C656.359863,48.762253 657.680542,47.511669 658.112244,46.515125
// 	C659.989502,42.182068 661.611694,37.738453 663.630981,32.553299
// 	C656.192627,33.332275 659.039673,40.524315 655.162903,43.092411
// 	C654.408142,40.889610 653.642029,38.736431 652.933899,36.564339
// 	C652.181152,34.255253 651.846008,31.415249 647.911377,33.314850
// 	C648.666626,35.645969 649.420837,37.973812 650.509521,40.996864
// M686.056274,29.663397
// 	C685.213013,26.273708 682.557190,28.119905 682.536682,29.214008
// 	C682.426331,35.110840 682.629578,41.069130 683.541687,46.877918
// 	C683.704529,47.914780 687.670288,48.570293 689.954163,48.926506
// 	C690.637268,49.033051 691.572144,47.525261 693.018372,46.169567
// 	C685.743164,45.801819 685.374207,45.115593 686.407898,36.731602
// 	C688.451538,35.865990 690.272583,35.094666 692.090759,34.324558
// 	C689.406799,32.644268 687.735718,31.598055 686.056274,29.663397
// M713.387024,35.145863
// 	C713.382080,38.805767 713.171326,42.483925 713.493469,46.114796
// 	C713.594482,47.253357 715.129395,48.264698 716.007996,49.334267
// 	C716.486633,49.030235 716.965332,48.726204 717.443970,48.422173
// 	C717.443970,44.348507 717.443970,40.274841 717.443970,36.026222
// 	C719.466492,35.899559 721.085388,35.798172 723.359009,35.655781
// 	C722.879700,34.442135 722.414307,32.703239 722.171265,32.733784
// 	C719.260925,33.099533 716.383484,33.727848 713.387024,35.145863
// M573.910400,28.779268
// 	C573.332642,27.538860 572.754944,26.298454 572.177185,25.058046
// 	C571.447388,26.245501 570.192139,27.388918 570.088318,28.628822
// 	C569.799072,32.080647 569.962158,35.572773 569.996643,39.048489
// 	C570.019592,41.360088 569.512878,43.953300 570.401062,45.889294
// 	C571.067139,47.341137 573.577148,47.946983 575.266235,48.929485
// 	C574.965393,47.625198 574.624207,46.328529 574.376160,45.014282
// 	C574.165588,43.898293 573.947876,42.763569 573.932617,41.634441
// 	C573.878784,37.662529 573.910889,33.689445 573.910400,28.779268
// M587.002869,44.335930
// 	C587.002869,45.794785 587.002869,47.253643 587.002869,48.766808
// 	C588.530029,48.766808 589.595032,48.766808 590.768188,48.766808
// 	C590.768188,41.455376 590.768188,34.366623 590.768188,27.239096
// 	C589.457397,27.239096 588.393311,27.239096 587.002808,27.239096
// 	C587.002808,32.761208 587.002808,38.063175 587.002869,44.335930
// M535.416565,48.786644
// 	C535.687927,43.553810 535.959290,38.320980 536.295532,31.836296
// 	C534.209717,33.047661 532.303162,33.603733 532.265686,34.265697
// 	C532.012512,38.734802 532.090942,43.228905 532.271667,47.706467
// 	C532.289246,48.140518 533.732056,48.517044 535.416565,48.786644
// M509.234131,40.473167
// 	C509.231720,38.642357 509.417786,36.782448 509.141632,34.993893
// 	C509.017090,34.187229 507.971527,33.522762 507.343811,32.793777
// 	C506.775787,33.423676 505.738831,34.037106 505.716064,34.686153
// 	C505.570129,38.841721 505.571442,43.008091 505.735413,47.162582
// 	C505.758881,47.757187 506.922943,48.306770 507.559143,48.877174
// 	C508.112183,48.230541 509.051605,47.633739 509.142670,46.927536
// 	C509.375061,45.126045 509.228851,43.275734 509.234131,40.473167
// z" />
//       <path fill="#1A1D3A" opacity="1.000000" stroke="none"
//             d="
// M332.336487,127.425354
// 	C329.131744,138.153030 326.066040,148.501968 322.979919,158.919769
// 	C317.113403,158.919769 311.472748,158.919769 305.335815,158.919769
// 	C297.187469,131.190155 289.018677,103.390991 280.705780,75.101372
// 	C286.714569,75.101372 292.194702,74.876801 297.630005,75.270699
// 	C298.876160,75.361008 300.652039,77.402145 301.026642,78.845833
// 	C305.450867,95.895432 309.648651,113.003731 313.932343,130.089981
// 	C314.194214,131.134460 314.677612,132.123398 315.408142,134.072617
// 	C320.877289,113.878746 326.075134,94.686493 331.320557,75.318573
// 	C336.874573,75.318573 342.323914,75.318573 348.154480,75.318573
// 	C353.426117,94.758636 358.704803,114.224846 363.983490,133.691055
// 	C364.243164,133.641953 364.502869,133.592865 364.762543,133.543777
// 	C369.561523,114.194084 374.360504,94.844391 379.203918,75.315437
// 	C385.560944,75.315437 391.820282,75.315437 398.657288,75.315437
// 	C395.946381,84.690384 393.351074,93.760948 390.699341,102.814980
// 	C385.796722,119.554306 380.688721,136.236420 376.052917,153.048965
// 	C374.795929,157.607727 373.081085,159.825882 368.062225,159.156525
// 	C364.467499,158.677078 360.757294,159.063293 356.615417,159.063293
// 	C351.179840,140.812729 345.753998,122.594864 340.328125,104.376999
// 	C340.005310,104.345016 339.682495,104.313034 339.359650,104.281059
// 	C337.064941,111.869576 334.770233,119.458092 332.336487,127.425354
// z" />
//       <path fill="#191C3A" opacity="1.000000" stroke="none"
//             d="
// M557.748657,120.732590
// 	C554.983643,122.873726 552.462158,124.749062 549.536926,126.924744
// 	C555.303833,137.246414 561.175720,147.755905 567.487915,159.053482
// 	C560.939148,159.053482 555.055420,159.192230 549.190125,158.925613
// 	C548.134216,158.877609 546.844604,157.336624 546.189392,156.185318
// 	C542.000427,148.824661 537.796021,141.463425 533.940369,133.926590
// 	C532.139526,130.406403 529.994385,128.580276 525.879700,129.172653
// 	C523.456848,129.521469 520.942688,129.236343 517.858276,129.236343
// 	C517.858276,139.239319 517.858276,148.943497 517.858276,158.844604
// 	C511.645355,158.844604 506.051819,158.844604 500.154541,158.844604
// 	C500.154541,131.088348 500.154541,103.388046 500.154541,74.604637
// 	C514.744446,75.148132 529.547668,74.184555 543.780396,76.592644
// 	C564.020081,80.017090 571.240967,104.207947 557.748657,120.732590
// M518.610840,115.019264
// 	C524.742371,114.793144 530.971558,115.162895 536.978638,114.177986
// 	C543.341919,113.134705 546.061035,108.809402 545.992371,102.312447
// 	C545.923767,95.819069 542.622131,91.126328 536.362244,90.217926
// 	C530.358398,89.346664 524.209961,89.471397 518.216125,89.163116
// 	C517.951477,90.447090 517.764282,90.930458 517.765320,91.413406
// 	C517.781921,99.070244 517.822632,106.727028 518.610840,115.019264
// z" />
//       <path fill="#1A1D3A" opacity="1.000000" stroke="none"
//             d="
// M424.243774,80.147415
// 	C439.844940,71.673485 455.318085,71.204659 469.832672,80.962204
// 	C485.100464,91.226082 488.326385,107.056458 486.419891,124.178955
// 	C484.673309,139.865311 477.198242,152.101013 461.809448,157.532181
// 	C446.967102,162.770508 432.502747,161.332504 420.033997,150.869141
// 	C409.568756,142.087021 406.180267,129.976990 406.273438,116.892639
// 	C406.379059,102.059959 410.957062,89.096092 424.243774,80.147415
// M424.984131,110.921486
// 	C425.025665,115.565430 424.616119,120.266647 425.197052,124.842102
// 	C426.678009,136.506058 434.500244,144.071793 445.264313,144.858368
// 	C455.157257,145.581299 463.755707,139.701492 466.812622,129.851517
// 	C469.494659,121.209518 469.535034,112.442627 466.608154,103.877876
// 	C464.084381,96.492722 459.154572,91.391243 451.124695,89.971748
// 	C437.355804,87.537743 428.192108,94.598221 424.984131,110.921486
// z" />
//       <path fill="#191C3A" opacity="1.000000" stroke="none"
//             d="
// M640.685730,84.685120
// 	C632.565613,94.158226 624.681274,103.366669 616.664001,112.730408
// 	C627.378418,128.031479 637.941772,143.116760 649.083374,159.027847
// 	C641.818298,159.027847 635.287598,159.158020 628.772400,158.898102
// 	C627.702148,158.855408 626.492981,157.243240 625.699341,156.110336
// 	C618.460388,145.777344 611.299255,135.389816 603.940491,124.765717
// 	C598.295898,130.187195 593.337891,135.286636 594.685425,143.937714
// 	C595.416626,148.631927 594.815796,153.533646 594.815796,158.700333
// 	C588.863892,158.700333 583.154236,158.700333 577.172485,158.700333
// 	C577.172485,131.032135 577.172485,103.327850 577.172485,75.362144
// 	C582.875122,75.362144 588.469482,75.362144 594.725342,75.362144
// 	C594.725342,87.476814 594.725342,99.641830 594.725342,111.806854
// 	C595.117004,111.967094 595.508606,112.127342 595.900269,112.287582
// 	C597.665222,110.224693 599.443726,108.173149 601.192871,106.096977
// 	C609.020874,96.805634 616.724121,87.404396 624.767883,78.304031
// 	C626.257263,76.618996 629.050476,75.430305 631.342712,75.242485
// 	C636.739319,74.800301 642.196716,75.100754 648.737366,75.100754
// 	C645.711182,78.709175 643.316345,81.564812 640.685730,84.685120
// z" />
//       <path fill="#191C3A" opacity="1.000000" stroke="none"
//             d="
// M682.271790,89.852661
// 	C677.989624,91.550186 675.437866,94.210854 675.587219,98.612137
// 	C675.740967,103.144798 679.002258,105.449051 682.743530,106.635124
// 	C689.225403,108.690025 695.992249,109.885017 702.395752,112.134682
// 	C706.865601,113.705017 711.429932,115.812653 715.079102,118.760872
// 	C725.823181,127.441292 725.478516,143.922516 714.807556,152.776917
// 	C702.381958,163.087250 675.626160,162.811264 663.331665,152.245926
// 	C657.953003,147.623718 654.931396,140.531830 655.281067,133.133011
// 	C660.730347,133.133011 666.191895,133.133011 671.663513,133.133011
// 	C676.642700,144.567993 686.634949,148.745926 697.830811,144.107224
// 	C701.852417,142.440964 704.651550,139.696091 704.741455,135.223434
// 	C704.831604,130.742554 701.707031,128.251953 697.880493,127.024628
// 	C692.039429,125.151176 686.024414,123.826424 680.149353,122.050034
// 	C676.350891,120.901489 672.461975,119.759903 668.973450,117.929268
// 	C654.605225,110.389404 652.914124,92.221420 665.449402,81.810753
// 	C676.920532,72.283783 698.668701,71.261063 711.002563,79.740547
// 	C717.572571,84.257431 721.217407,90.490532 721.654053,98.824615
// 	C716.121887,98.824615 710.671875,98.824615 705.142639,98.824615
// 	C700.064758,89.261269 694.170349,86.846428 682.271790,89.852661
// z" />
//       <path fill="#3F67B1" opacity="1.000000" stroke="none"
//             d="
// M202.805359,133.351837
// 	C200.110931,129.993118 197.416504,126.634392 194.186981,122.608658
// 	C187.943878,130.819183 181.831161,138.858246 175.250656,146.679306
// 	C174.521698,138.375488 174.318802,130.287231 173.979706,122.204666
// 	C173.737854,116.440163 173.312927,110.683334 172.969696,104.923080
// 	C176.444031,101.160454 176.705170,97.479614 172.919342,93.344849
// 	C173.432846,91.033585 173.954330,89.286209 173.962280,87.536491
// 	C174.028427,72.960304 173.936707,58.383038 174.089233,43.808155
// 	C174.106110,42.194839 175.354553,40.594414 176.031830,38.988007
// 	C185.808975,51.860538 195.586121,64.733070 205.200119,78.344055
// 	C204.691315,81.170738 204.059692,83.257149 204.046295,85.347511
// 	C203.957077,99.270081 204.049255,113.193855 203.946686,127.116241
// 	C203.931351,129.197464 203.203568,131.273438 202.805359,133.351837
// z" />
//       <path fill="#426EB6" opacity="1.000000" stroke="none"
//             d="
// M175.934937,38.662582
// 	C175.354553,40.594414 174.106110,42.194839 174.089233,43.808155
// 	C173.936707,58.383038 174.028427,72.960304 173.962280,87.536491
// 	C173.954330,89.286209 173.432846,91.033585 172.751373,93.021645
// 	C155.191849,70.908882 138.030334,48.556522 120.047134,25.133947
// 	C125.299332,25.133947 129.341797,25.134058 133.384247,25.133924
// 	C142.687622,25.133617 151.993958,25.266647 161.292816,25.061512
// 	C164.614685,24.988230 166.890762,25.838701 168.721786,28.736576
// 	C170.843704,32.094845 173.444916,35.150265 175.934937,38.662582
// z" />
//       <path fill="#3F60AC" opacity="1.000000" stroke="none"
//             d="
// M202.902390,133.668335
// 	C203.203568,131.273438 203.931351,129.197464 203.946686,127.116241
// 	C204.049255,113.193855 203.957077,99.270081 204.046295,85.347511
// 	C204.059692,83.257149 204.691315,81.170738 205.297302,78.656372
// 	C215.284943,90.944435 225.012268,103.658615 234.437653,116.791458
// 	C233.757156,118.885002 233.062683,120.557556 233.050339,122.235130
// 	C232.957458,134.848465 232.998962,147.462769 232.998459,160.076782
// 	C226.001343,161.583786 220.923126,159.688080 217.004044,153.117661
// 	C212.973175,146.359879 207.718552,140.332062 202.902390,133.668335
// z" />
//       <path fill="#3A83C3" opacity="1.000000" stroke="none"
//             d="
// M109.329933,37.871094
// 	C112.883591,41.239185 116.437248,44.607273 119.909821,48.704628
// 	C119.554878,50.547832 119.043869,51.661350 119.041924,52.775761
// 	C118.995926,79.033302 118.972954,105.291100 119.105858,131.548035
// 	C119.111946,132.751694 120.610245,133.947800 121.413544,135.147415
// 	C117.270935,139.436600 113.128319,143.725784 108.315582,147.829651
// 	C107.434212,146.859604 107.038910,146.075027 107.038147,145.290054
// 	C107.004547,110.704559 106.981949,76.118942 107.100876,41.533829
// 	C107.105080,40.310379 108.554230,39.091896 109.329933,37.871094
// z" />
//       <path fill="#3AC1BB" opacity="1.000000" stroke="none"
//             d="
// M22.036190,156.731079
// 	C29.477243,157.612656 36.839035,158.022614 44.907696,158.471924
// 	C44.907696,155.927231 44.907692,154.159805 44.907692,152.392365
// 	C44.907703,123.406868 44.906128,94.421364 44.912251,65.435867
// 	C44.912567,63.938740 44.769306,62.392910 45.101528,60.964813
// 	C45.270683,60.237686 46.369785,59.222881 47.011990,59.248646
// 	C47.681793,59.275520 48.580666,60.292141 48.880356,61.068645
// 	C49.218960,61.945976 49.023296,63.037754 49.023418,64.036278
// 	C49.027214,95.020775 48.949959,126.005676 49.115795,156.989288
// 	C49.138538,161.238586 47.758064,162.473541 43.679352,162.314148
// 	C36.693508,162.041183 29.685022,162.093170 22.694586,162.298889
// 	C19.160305,162.402893 17.667900,161.494034 17.678267,157.597580
// 	C17.793489,114.286240 17.776169,70.974419 17.701391,27.662891
// 	C17.695463,24.228365 18.840010,22.908472 22.338942,22.996929
// 	C29.663912,23.182119 36.999874,22.941774 44.324039,23.142223
// 	C45.847118,23.183907 47.338619,24.379274 48.844673,25.042957
// 	C47.268703,25.766693 45.711040,27.064426 44.113617,27.115368
// 	C36.983162,27.342764 29.841352,27.214172 21.956930,27.214172
// 	C21.956930,70.475487 21.956930,113.367470 22.036190,156.731079
// z" />
//       <path fill="#426FB6" opacity="1.000000" stroke="none"
//             d="
// M172.677704,105.091721
// 	C173.312927,110.683334 173.737854,116.440163 173.979706,122.204666
// 	C174.318802,130.287231 174.521698,138.375488 174.958450,146.863785
// 	C172.505310,150.892822 169.992233,154.613281 167.173782,158.085892
// 	C166.380325,159.063538 164.697937,159.803192 163.411819,159.820892
// 	C153.276962,159.960449 143.139328,159.898392 131.863113,159.898392
// 	C145.720444,141.214081 159.053085,123.237213 172.677704,105.091721
// z" />
//       <path fill="#358DC9" opacity="1.000000" stroke="none"
//             d="
// M109.132446,37.581879
// 	C108.554230,39.091896 107.105080,40.310379 107.100876,41.533829
// 	C106.981949,76.118942 107.004547,110.704559 107.038147,145.290054
// 	C107.038910,146.075027 107.434212,146.859604 107.996292,147.917969
// 	C105.824043,149.836792 103.300980,151.482010 100.368942,152.780151
// 	C99.640068,150.318604 99.060806,148.206223 99.043587,146.089279
// 	C98.951653,134.780914 99.067032,123.470596 98.931679,112.163094
// 	C98.910095,110.360207 97.981163,108.568184 97.471710,106.771133
// 	C100.893448,95.943001 100.640160,85.300888 96.122665,74.340744
// 	C96.924873,71.994186 97.908058,70.199791 97.927856,68.394836
// 	C98.064140,55.970692 97.999092,43.544338 97.998817,31.118698
// 	C101.644196,33.176685 105.289581,35.234673 109.132446,37.581879
// z" />
//       <path fill="#3D7BBE" opacity="1.000000" stroke="none"
//             d="
// M121.675407,134.954681
// 	C120.610245,133.947800 119.111946,132.751694 119.105858,131.548035
// 	C118.972954,105.291100 118.995926,79.033302 119.041924,52.775761
// 	C119.043869,51.661350 119.554878,50.547832 120.003815,49.018250
// 	C138.917145,72.509132 139.666595,109.231644 121.675407,134.954681
// z" />
//       <path fill="#3F5AA8" opacity="1.000000" stroke="none"
//             d="
// M233.459213,160.172546
// 	C232.998962,147.462769 232.957458,134.848465 233.050339,122.235130
// 	C233.062683,120.557556 233.757156,118.885002 234.525482,117.116425
// 	C245.677002,131.138336 256.438782,145.253952 267.885742,160.268326
// 	C255.755234,160.268326 244.837601,160.268326 233.459213,160.172546
// z" />
//       <path fill="#4B60AA" opacity="1.000000" stroke="none"
//             d="
// M219.779205,47.798344
// 	C215.519104,53.365295 211.550812,58.710476 207.372864,63.886429
// 	C206.613220,64.827530 204.996414,65.076729 203.775299,65.645325
// 	C203.891663,64.192825 203.468292,62.336998 204.206467,61.349174
// 	C213.267929,49.223228 222.445496,37.182198 231.769867,25.257462
// 	C232.707977,24.057711 234.788635,23.173550 236.359634,23.141184
// 	C245.842239,22.945814 255.331482,22.986765 264.816650,23.101423
// 	C266.156647,23.117619 267.486572,23.967691 268.821136,24.431799
// 	C268.295166,25.757801 268.024780,27.272640 267.205261,28.381718
// 	C253.068848,47.512753 238.895538,66.616913 224.600388,85.629288
// 	C223.726974,86.790909 221.771484,87.138916 220.319489,87.865501
// 	C220.637131,86.279099 220.476639,84.341858 221.344437,83.159111
// 	C233.645020,66.394554 246.086166,49.733128 258.489594,33.043991
// 	C259.668457,31.457819 260.822174,29.852989 262.677490,27.311901
// 	C253.404053,27.311901 245.036270,27.207581 236.677505,27.434639
// 	C235.542770,27.465464 234.248627,28.995321 233.376923,30.104946
// 	C228.855347,35.860531 224.446930,41.705006 219.779205,47.798344
// z" />
//       <path fill="#1EB2E0" opacity="1.000000" stroke="none"
//             d="
// M67.003784,159.959290
// 	C63.229050,159.959290 59.454315,159.959290 55.360386,159.959290
// 	C55.360386,148.798798 55.360386,137.803619 55.360386,126.143761
// 	C58.950077,126.143761 62.522285,126.143761 66.549324,126.675339
// 	C67.004028,138.124374 67.003906,149.041824 67.003784,159.959290
// z" />
//       <path fill="#3096CF" opacity="1.000000" stroke="none"
//             d="
// M97.704346,30.907726
// 	C97.999092,43.544338 98.064140,55.970692 97.927856,68.394836
// 	C97.908058,70.199791 96.924873,71.994186 96.002800,73.982948
// 	C92.775879,71.026085 89.933647,67.879730 87.231567,64.209625
// 	C87.914383,61.158932 88.873741,58.640446 88.925247,56.103539
// 	C89.115601,46.726044 89.006042,37.342457 89.009216,27.961163
// 	C91.809441,28.873028 94.609657,29.784893 97.704346,30.907726
// z" />
//       <path fill="#1BABDB" opacity="1.000000" stroke="none"
//             d="
// M73.916763,59.386208
// 	C70.579498,59.303520 67.242233,59.220833 63.452698,58.591522
// 	C63.007988,47.148071 63.015549,36.251240 63.023113,25.354412
// 	C66.473984,25.309019 69.924858,25.263626 73.724831,25.839516
// 	C74.021545,37.435932 73.969154,48.411072 73.916763,59.386208
// z" />
//       <path fill="#1DA7D9" opacity="1.000000" stroke="none"
//             d="
// M67.456482,160.038193
// 	C67.003906,149.041824 67.004028,138.124374 67.004738,126.759727
// 	C69.980484,126.049568 72.955650,125.786583 76.531944,125.913719
// 	C77.421936,128.227646 77.942581,130.149323 77.960243,132.075623
// 	C78.044670,141.287735 77.992752,150.501114 77.989304,159.714035
// 	C74.629265,159.848389 71.269226,159.982742 67.456482,160.038193
// z" />
//       <path fill="#299ED3" opacity="1.000000" stroke="none"
//             d="
// M88.720268,27.720329
// 	C89.006042,37.342457 89.115601,46.726044 88.925247,56.103539
// 	C88.873741,58.640446 87.914383,61.158932 87.045105,63.926857
// 	C84.195412,63.021202 81.672340,61.874554 79.235809,60.211040
// 	C79.548340,57.923923 79.957573,56.154926 79.969688,54.383217
// 	C80.034157,44.952969 79.999153,35.522038 79.998581,26.091341
// 	C82.809494,26.554058 85.620407,27.016775 88.720268,27.720329
// z" />
//       <path fill="#3193CD" opacity="1.000000" stroke="none"
//             d="
// M97.296104,107.055237
// 	C97.981163,108.568184 98.910095,110.360207 98.931679,112.163094
// 	C99.067032,123.470596 98.951653,134.780914 99.043587,146.089279
// 	C99.060806,148.206223 99.640068,150.318604 100.025803,152.871826
// 	C97.684959,154.323059 95.278275,155.335541 92.461693,155.888336
// 	C92.034615,144.566696 92.070999,133.704163 91.943596,122.843536
// 	C91.924065,121.178391 91.149200,119.522110 90.723663,117.861725
// 	C92.855942,114.354256 94.988220,110.846794 97.296104,107.055237
// z" />
//       <path fill="#27A0D4" opacity="1.000000" stroke="none"
//             d="
// M78.367111,159.768265
// 	C77.992752,150.501114 78.044670,141.287735 77.960243,132.075623
// 	C77.942581,130.149323 77.421936,128.227646 76.974274,125.865631
// 	C79.329445,124.451706 81.843414,123.475983 84.693764,122.805908
// 	C85.353325,125.706284 85.922287,128.297897 85.955246,130.896301
// 	C86.070389,139.975021 86.000404,149.056061 86.000755,158.136230
// 	C83.582146,158.698318 81.163536,159.260406 78.367111,159.768265
// z" />
//       <path fill="#22B5E2" opacity="1.000000" stroke="none"
//             d="
// M62.581688,25.262737
// 	C63.015549,36.251240 63.007988,47.148071 63.001694,58.512032
// 	C60.547028,58.979160 58.091095,58.979160 55.332569,58.979160
// 	C55.332569,47.829601 55.332569,36.820496 55.332569,25.171061
// 	C57.646229,25.171061 59.893250,25.171061 62.581688,25.262737
// z" />
//       <path fill="#2C99D0" opacity="1.000000" stroke="none"
//             d="
// M86.370621,158.188843
// 	C86.000404,149.056061 86.070389,139.975021 85.955246,130.896301
// 	C85.922287,128.297897 85.353325,125.706284 85.017044,122.710159
// 	C86.721367,120.928062 88.438789,119.547363 90.439941,118.014191
// 	C91.149200,119.522110 91.924065,121.178391 91.943596,122.843536
// 	C92.070999,133.704163 92.034615,144.566696 92.085526,155.931458
// 	C90.326324,157.036697 88.533401,157.639084 86.370621,158.188843
// z" />
//       <path fill="#282B46" opacity="1.000000" stroke="none"
//             d="
// M496.978638,47.223099
// 	C492.490173,48.152908 488.344513,48.890575 483.180664,49.809422
// 	C483.180664,42.254498 483.127045,35.850513 483.256744,29.450241
// 	C483.270996,28.746956 484.198914,27.736080 484.937103,27.427156
// 	C489.307556,25.598225 497.446869,28.022038 499.856049,31.733007
// 	C502.832764,36.318275 501.789551,42.614712 496.978638,47.223099
// M492.516998,45.142254
// 	C496.779785,42.974144 498.431793,39.325420 496.824005,35.013878
// 	C495.128906,30.468220 491.466797,30.395342 487.333984,31.066013
// 	C487.333984,35.897541 487.333984,40.483360 487.333984,45.607922
// 	C488.945862,45.530613 490.348694,45.463333 492.516998,45.142254
// z" />
//       <path fill="#272A46" opacity="1.000000" stroke="none"
//             d="
// M521.213745,47.983696
// 	C514.975586,47.355923 512.225525,44.021641 513.176941,38.802219
// 	C514.243652,32.950268 519.136963,31.054443 527.432617,33.501915
// 	C527.432617,37.204281 527.450623,41.006130 527.429077,44.807755
// 	C527.376709,54.050610 523.382141,56.668415 512.674438,52.843449
// 	C515.039368,51.972591 516.149414,51.384342 517.326721,51.176060
// 	C518.773499,50.920113 520.434509,51.350224 521.711060,50.803001
// 	C523.018555,50.242523 525.285522,48.814121 521.213745,47.983696
// M521.589050,35.435539
// 	C517.507690,36.761196 515.809631,39.523647 517.474976,42.566784
// 	C518.026123,43.573807 519.320129,44.505085 520.438904,44.765965
// 	C521.334656,44.974842 523.386108,44.073849 523.370361,43.738400
// 	C523.241638,40.996490 522.747925,38.271717 521.589050,35.435539
// z" />
//       <path fill="#21A4D7" opacity="1.000000" stroke="none"
//             d="
// M79.590744,25.987520
// 	C79.999153,35.522038 80.034157,44.952969 79.969688,54.383217
// 	C79.957573,56.154926 79.548340,57.923923 78.971428,59.978851
// 	C77.245461,60.094826 75.870422,59.926121 74.206070,59.571815
// 	C73.969154,48.411072 74.021545,37.435932 74.120575,25.950085
// 	C75.839111,25.587482 77.511009,25.735590 79.590744,25.987520
// z" />
//       <path fill="#31344E" opacity="1.000000" stroke="none"
//             d="
// M564.069702,33.963619
// 	C564.789429,38.313286 565.372192,42.359879 565.555054,46.424461
// 	C565.591492,47.234543 564.244263,48.479248 563.273193,48.866188
// 	C562.109009,49.330063 560.664551,49.105625 559.340515,49.150452
// 	C555.642334,49.275669 552.445801,48.364193 552.482239,43.954311
// 	C552.515991,39.867790 555.567505,38.911903 559.055420,38.902092
// 	C559.865662,38.899811 560.675964,38.901760 561.486206,38.901760
// 	C561.664612,38.413456 561.842957,37.925156 562.021301,37.436852
// 	C560.299011,36.848202 558.569458,36.279537 556.857239,35.662868
// 	C555.930115,35.328957 555.032471,34.913155 554.121521,34.534286
// 	C554.979614,33.788208 555.866699,32.384293 556.690308,32.420567
// 	C559.071289,32.525433 561.426697,33.209927 564.069702,33.963619
// z" />
//       <path fill="#2E314C" opacity="1.000000" stroke="none"
//             d="
// M671.936401,38.909508
// 	C675.504822,38.091114 674.316772,36.788757 672.504639,35.987602
// 	C671.397644,35.498215 669.901489,35.972488 668.644165,35.708683
// 	C667.902222,35.552990 667.285339,34.801254 666.612183,34.317577
// 	C667.058960,33.747517 667.386047,32.935688 667.971558,32.645752
// 	C672.520508,30.393181 677.735596,33.413750 677.975891,38.487099
// 	C678.130676,41.755150 678.004944,45.036484 678.004944,48.168167
// 	C669.736328,50.202351 665.404419,49.086769 664.959045,44.905212
// 	C664.449768,40.124298 667.552612,38.976498 671.936401,38.909508
// z" />
//       <path fill="#272A46" opacity="1.000000" stroke="none"
//             d="
// M625.729858,34.508034
// 	C626.485657,39.366154 626.998718,43.890198 627.511780,48.414246
// 	C626.940125,48.730728 626.368469,49.047207 625.796875,49.363689
// 	C624.931763,48.391262 623.557251,47.530190 623.314941,46.421638
// 	C622.899475,44.520836 623.130615,42.463291 623.199341,40.475334
// 	C623.335571,36.534615 621.757080,34.887642 617.406982,36.272255
// 	C617.406982,40.323917 617.406982,44.405006 617.406982,48.486099
// 	C616.941284,48.777042 616.475586,49.067989 616.009888,49.358936
// 	C615.191956,48.377678 613.731873,47.436241 613.668091,46.408222
// 	C613.402161,42.119690 613.556152,37.805122 613.556152,33.388416
// 	C617.786865,32.141010 621.753784,31.127008 625.729858,34.508034
// z" />
//       <path fill="#30324D" opacity="1.000000" stroke="none"
//             d="
// M609.052673,43.919449
// 	C608.981995,45.843700 608.911316,47.303516 608.840637,48.763336
// 	C608.397156,49.009880 607.953613,49.256424 607.510132,49.502964
// 	C606.709534,48.418938 605.456116,47.427254 605.211914,46.229771
// 	C604.821228,44.314247 605.054199,42.260983 605.105652,40.268066
// 	C605.214111,36.063869 603.245544,34.960884 599.236816,36.402851
// 	C599.236816,38.390343 599.236877,40.363880 599.236877,42.337414
// 	C599.236877,44.325073 599.236816,46.312733 599.236816,48.300392
// 	C598.845093,48.620750 598.453430,48.941109 598.061707,49.261467
// 	C597.170105,48.238674 595.588867,47.266605 595.509216,46.184086
// 	C595.205811,42.059322 595.386841,37.898918 595.386841,33.847221
// 	C605.213562,29.705616 609.046143,32.471481 609.052673,43.919449
// z" />
//       <path fill="#272A46" opacity="1.000000" stroke="none"
//             d="
// M704.389526,48.921642
// 	C700.229736,49.643761 697.047913,48.820442 695.111267,45.234608
// 	C692.905762,41.151112 694.244629,35.464470 697.849792,33.286118
// 	C701.431030,31.122232 706.116943,32.200165 708.340820,35.699364
// 	C711.219299,40.228718 709.795288,45.482407 704.389526,48.921642
// M699.965820,36.160862
// 	C699.538086,38.718754 698.363220,41.440121 698.877686,43.791870
// 	C699.478577,46.538212 703.367126,46.897892 704.617615,44.428467
// 	C705.484070,42.717373 705.781006,40.177303 705.151917,38.433517
// 	C704.709656,37.207634 702.281738,36.698090 699.965820,36.160862
// z" />
//       <path fill="#282B47" opacity="1.000000" stroke="none"
//             d="
// M633.214111,34.281982
// 	C637.044128,31.464067 640.667114,31.321798 643.836853,34.403187
// 	C646.926147,37.406414 646.903625,41.271915 645.341492,44.988773
// 	C643.861694,48.509827 640.800537,49.606724 637.169861,49.111317
// 	C630.940308,48.261261 628.909119,41.218567 633.214111,34.281982
// M634.752380,41.308147
// 	C636.109497,42.845932 637.466553,44.383713 638.823669,45.921490
// 	C639.972168,44.171364 641.908875,42.473339 642.029053,40.655270
// 	C642.132263,39.093838 640.452515,36.364426 639.046143,35.964996
// 	C635.802246,35.043720 634.906372,37.691475 634.752380,41.308147
// z" />
//       <path fill="#2C2F4A" opacity="1.000000" stroke="none"
//             d="
// M544.877075,32.220406
// 	C546.707581,32.625656 548.183716,32.751415 549.659790,32.877171
// 	C549.869019,33.357166 550.078247,33.837158 550.287415,34.317150
// 	C548.450500,35.108246 546.613586,35.899342 544.242004,36.920692
// 	C544.242004,37.991669 544.234253,39.607742 544.243713,41.223713
// 	C544.259705,43.939457 544.581970,46.307781 548.320679,45.918114
// 	C548.959839,45.851494 549.704102,46.794022 550.399841,47.270355
// 	C549.749268,47.862114 549.190186,48.779171 548.433105,48.992161
// 	C543.948120,50.253841 540.889465,48.179504 540.557556,43.410221
// 	C540.269714,39.274063 540.276672,35.095963 540.518555,30.957752
// 	C540.588745,29.756903 541.916687,28.629572 542.668091,27.468540
// 	C543.097534,27.699055 543.527039,27.929569 543.956543,28.160084
// 	C544.145264,29.420361 544.333984,30.680635 544.877075,32.220406
// z" />
//       <path fill="#232642" opacity="1.000000" stroke="none"
//             d="
// M650.342285,40.649261
// 	C649.420837,37.973812 648.666626,35.645969 647.911377,33.314850
// 	C651.846008,31.415249 652.181152,34.255253 652.933899,36.564339
// 	C653.642029,38.736431 654.408142,40.889610 655.162903,43.092411
// 	C659.039673,40.524315 656.192627,33.332275 663.630981,32.553299
// 	C661.611694,37.738453 659.989502,42.182068 658.112244,46.515125
// 	C657.680542,47.511669 656.359863,48.762253 655.472046,48.743935
// 	C654.567322,48.725266 653.432373,47.435066 652.861450,46.436718
// 	C651.886841,44.732426 651.274231,42.821178 650.342285,40.649261
// z" />
//       <path fill="#262945" opacity="1.000000" stroke="none"
//             d="
// M686.060425,30.107620
// 	C687.735718,31.598055 689.406799,32.644268 692.090759,34.324558
// 	C690.272583,35.094666 688.451538,35.865990 686.407898,36.731602
// 	C685.374207,45.115593 685.743164,45.801819 693.018372,46.169567
// 	C691.572144,47.525261 690.637268,49.033051 689.954163,48.926506
// 	C687.670288,48.570293 683.704529,47.914780 683.541687,46.877918
// 	C682.629578,41.069130 682.426331,35.110840 682.536682,29.214008
// 	C682.557190,28.119905 685.213013,26.273708 686.060425,30.107620
// z" />
//       <path fill="#363852" opacity="1.000000" stroke="none"
//             d="
// M713.442261,34.716690
// 	C716.383484,33.727848 719.260925,33.099533 722.171265,32.733784
// 	C722.414307,32.703239 722.879700,34.442135 723.359009,35.655781
// 	C721.085388,35.798172 719.466492,35.899559 717.443970,36.026222
// 	C717.443970,40.274841 717.443970,44.348507 717.443970,48.422173
// 	C716.965332,48.726204 716.486633,49.030235 716.007996,49.334267
// 	C715.129395,48.264698 713.594482,47.253357 713.493469,46.114796
// 	C713.171326,42.483925 713.382080,38.805767 713.442261,34.716690
// z" />
//       <path fill="#272A46" opacity="1.000000" stroke="none"
//             d="
// M573.910400,29.248037
// 	C573.910889,33.689445 573.878784,37.662529 573.932617,41.634441
// 	C573.947876,42.763569 574.165588,43.898293 574.376160,45.014282
// 	C574.624207,46.328529 574.965393,47.625198 575.266235,48.929489
// 	C573.577148,47.946983 571.067139,47.341137 570.401062,45.889294
// 	C569.512878,43.953300 570.019592,41.360088 569.996643,39.048489
// 	C569.962158,35.572773 569.799072,32.080647 570.088318,28.628822
// 	C570.192139,27.388918 571.447388,26.245501 572.177185,25.058046
// 	C572.754944,26.298454 573.332642,27.538860 573.910400,29.248037
// z" />
//       <path fill="#161937" opacity="1.000000" stroke="none"
//             d="
// M587.002808,43.850536
// 	C587.002808,38.063175 587.002808,32.761208 587.002808,27.239096
// 	C588.393311,27.239096 589.457397,27.239096 590.768188,27.239096
// 	C590.768188,34.366623 590.768188,41.455376 590.768188,48.766808
// 	C589.595032,48.766808 588.530029,48.766808 587.002869,48.766808
// 	C587.002869,47.253643 587.002869,45.794785 587.002808,43.850536
// z" />
//       <path fill="#272A45" opacity="1.000000" stroke="none"
//             d="
// M534.964905,48.853462
// 	C533.732056,48.517044 532.289246,48.140518 532.271667,47.706467
// 	C532.090942,43.228905 532.012512,38.734802 532.265686,34.265697
// 	C532.303162,33.603733 534.209717,33.047661 536.295532,31.836296
// 	C535.959290,38.320980 535.687927,43.553810 534.964905,48.853462
// z" />
//       <path fill="#181B39" opacity="1.000000" stroke="none"
//             d="
// M509.234131,40.959061
// 	C509.228851,43.275734 509.375061,45.126045 509.142670,46.927536
// 	C509.051605,47.633739 508.112183,48.230541 507.559143,48.877182
// 	C506.922943,48.306770 505.758881,47.757187 505.735413,47.162582
// 	C505.571442,43.008091 505.570129,38.841721 505.716064,34.686153
// 	C505.738831,34.037106 506.775787,33.423676 507.343811,32.793777
// 	C507.971527,33.522762 509.017090,34.187229 509.141632,34.993893
// 	C509.417786,36.782448 509.231720,38.642357 509.234131,40.959061
// z" />
//       <path fill="#FBFBFB" opacity="1.000000" stroke="none"
//             d="
// M518.234375,114.701538
// 	C517.822632,106.727028 517.781921,99.070244 517.765320,91.413406
// 	C517.764282,90.930458 517.951477,90.447090 518.216125,89.163116
// 	C524.209961,89.471397 530.358398,89.346664 536.362244,90.217926
// 	C542.622131,91.126328 545.923767,95.819069 545.992371,102.312447
// 	C546.061035,108.809402 543.341919,113.134705 536.978638,114.177986
// 	C530.971558,115.162895 524.742371,114.793144 518.234375,114.701538
// z" />
//       <path fill="#FDFDFD" opacity="1.000000" stroke="none"
//             d="
// M425.017670,110.472084
// 	C428.192108,94.598221 437.355804,87.537743 451.124695,89.971748
// 	C459.154572,91.391243 464.084381,96.492722 466.608154,103.877876
// 	C469.535034,112.442627 469.494659,121.209518 466.812622,129.851517
// 	C463.755707,139.701492 455.157257,145.581299 445.264313,144.858368
// 	C434.500244,144.071793 426.678009,136.506058 425.197052,124.842102
// 	C424.616119,120.266647 425.025665,115.565430 425.017670,110.472084
// z" />
//       <path fill="#F4F4F6" opacity="1.000000" stroke="none"
//             d="
// M492.134247,45.269154
// 	C490.348694,45.463333 488.945862,45.530613 487.333984,45.607922
// 	C487.333984,40.483360 487.333984,35.897541 487.333984,31.066013
// 	C491.466797,30.395342 495.128906,30.468220 496.824005,35.013878
// 	C498.431793,39.325420 496.779785,42.974144 492.134247,45.269154
// z" />
//       <path fill="#E8E8EB" opacity="1.000000" stroke="none"
//             d="
// M521.977051,35.488640
// 	C522.747925,38.271717 523.241638,40.996490 523.370361,43.738400
// 	C523.386108,44.073849 521.334656,44.974842 520.438904,44.765965
// 	C519.320129,44.505085 518.026123,43.573807 517.474976,42.566784
// 	C515.809631,39.523647 517.507690,36.761196 521.977051,35.488640
// z" />
//       <path fill="#F3F3F4" opacity="1.000000" stroke="none"
//             d="
// M700.353149,36.014767
// 	C702.281738,36.698090 704.709656,37.207634 705.151917,38.433517
// 	C705.781006,40.177303 705.484070,42.717373 704.617615,44.428467
// 	C703.367126,46.897892 699.478577,46.538212 698.877686,43.791870
// 	C698.363220,41.440121 699.538086,38.718754 700.353149,36.014767
// z" />
//       <path fill="#FAFAFB" opacity="1.000000" stroke="none"
//             d="
// M634.749512,40.882721
// 	C634.906372,37.691475 635.802246,35.043720 639.046143,35.964996
// 	C640.452515,36.364426 642.132263,39.093838 642.029053,40.655270
// 	C641.908875,42.473339 639.972168,44.171364 638.823669,45.921494
// 	C637.466553,44.383713 636.109497,42.845932 634.749512,40.882721
// z" />
//     </svg>
//   )
// }


export function LogoIcon() {
    // @ts-ignore
    return (
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M13.931 10.245l2.021-2.042-2.778-.403-1.223-2.653-1.222 2.653-2.778.402 2.021 2.042-.492 2.903 2.471-1.391 2.472 1.391-.492-2.902zm-9.481 4.518c-.866-1.382-1.374-3.012-1.374-4.763 0-4.971 4.029-9 9-9s9 4.029 9 9c0 1.792-.53 3.458-1.433 4.861-.607-.31-1.228-.585-1.862-.819.812-1.143 1.295-2.536 1.295-4.042 0-3.86-3.141-7-7-7s-7 3.14-7 7c0 1.476.462 2.844 1.244 3.974-.636.225-1.26.488-1.87.789zm15.307 2.45l-2.334 3.322c-1.603-.924-3.448-1.464-5.423-1.473-1.975.009-3.82.549-5.423 1.473l-2.334-3.322c2.266-1.386 4.912-2.202 7.757-2.211 2.845.009 5.491.825 7.757 2.211zm4.243 2.787h-2.359l-.566 3c-.613-1.012-1.388-1.912-2.277-2.68l2.343-3.335c1.088.879 2.052 1.848 2.859 3.015zm-21.14-3.015l2.343 3.335c-.89.769-1.664 1.668-2.277 2.68l-.566-3h-2.36c.807-1.167 1.771-2.136 2.86-3.015z"/></svg>
            <span style={{fontSize: "25px"}}><b>Digital Badge Maker</b></span>
        </div>
    )
}