import './Navbar.scss'
import { DownloadSVGIcon, DownloadPNGIcon, LogoIcon, GithubIcon } from './NavbarIcons'
import { useCanvasContext } from '@components/Canvas/hooks'
import {fabric} from "fabric";

function Navbar() {

    const { canvas } = useCanvasContext()

    function handleSVGDownloadClick() {

        //@ts-ignore
        canvas.getObjects().find(obj => obj.id === 'workarea').set({fill: 'transparent'})
        canvas.renderAll()

        const svgData = canvas.toSVG();

        if (svgData) {
            const blob = new Blob([svgData], { type: 'image/svg+xml' });
            const url = URL.createObjectURL(blob);

            // const fileName = window.prompt('저장 SVG 파일 명 입력');
            //
            // if (fileName) {
            //     const a = document.createElement('a');
            //     a.href = url;
            //     a.download = fileName;
            //     a.click();
            // }

            // 현재 날짜를 가져오는 함수
            // @ts-ignore
            function getCurrentDate() {
                const today = new Date();
                const year = today.getFullYear();
                const month = (today.getMonth() + 1).toString().padStart(2, '0');
                const day = today.getDate().toString().padStart(2, '0');

                return `${year}${month}${day}`;
            }

            const currentDate = getCurrentDate();
            const fileName = `${currentDate}_badge.svg`;

            const a = document.createElement('a');
            a.href = url;
            a.download = fileName
            a.click();

            URL.revokeObjectURL(url);
        }

        //@ts-ignore
        canvas.getObjects().find(obj => obj.id === 'workarea').set({fill: '#ffffff'})
        canvas.renderAll()
    }

    function handlePNGDownloadClick() {

        //@ts-ignore
        canvas.getObjects().find(obj => obj.id === 'workarea').set({fill: 'transparent'})
        canvas.renderAll()

        // 복사할 캔버스 생성자
        const canvasTmp = new fabric.Canvas(null);

        // 기존 캔버스의 크기를 복사한 캔버스에 설정
        canvasTmp.setWidth(canvas.getWidth());
        canvasTmp.setHeight(canvas.getHeight());

        // 기존 캔버스의 내용을 복사한 캔버스로 복사
        canvasTmp.loadFromJSON(canvas.toJSON(), canvasTmp.renderAll.bind(canvasTmp));

        //@ts-ignore
        canvasTmp.remove(canvasTmp.getObjects().find(obj => obj.type === 'rect'))
        canvasTmp.renderAll()

        // 현재 날짜를 가져오는 함수
        // @ts-ignore
        function getCurrentDate() {
            const today = new Date();
            const year = today.getFullYear();
            const month = (today.getMonth() + 1).toString().padStart(2, '0');
            const day = today.getDate().toString().padStart(2, '0');

            return `${year}${month}${day}`;
        }

        const currentDate = getCurrentDate();
        const fileName = `${currentDate}_badge.png`;

        // 렌더링 확인 이후 대기 시간 추가
        setTimeout(() => {
            // @ts-ignore
            const dataURL = canvasTmp.toDataURL('image/png');

            // 다운로드 링크를 생성하고 클릭
            const a = document.createElement('a');
            a.href = dataURL;
            a.download = fileName;
            a.click();
        }, 1000); // 1초 대기

        //@ts-ignore
        canvas.getObjects().find(obj => obj.id === 'workarea').set({fill: '#ffffff'})
        canvas.renderAll()
    }

  // @ts-ignore
    return (
    <div className="navbar">
      <div className="navbar-left">
        <LogoIcon />
      </div>
        <div className={"navbar-right"}>
            <div className="navbar-action-items" onClick={handleSVGDownloadClick}>
                {/*<DownloadSVGIcon/>*/}
                {/*<a href="https://github.com/xorb/react-design-editor">*/}
                {/*  <GithubIcon />*/}
                {/*</a>*/}
            </div>
            {/*<div className="navbar-action-items" onClick={handlePNGDownloadClick}>*/}
            {/*    <DownloadPNGIcon/>*/}
            {/*    /!*<a href="https://github.com/xorb/react-design-editor">*!/*/}
            {/*    /!*  <GithubIcon />*!/*/}
            {/*    /!*</a>*!/*/}
            {/*</div>*/}
        </div>
    </div>
  )
}

export default Navbar
