import { useCallback, useEffect } from 'react'
import { useCanvasContext } from '@components/Canvas/hooks'
// import {isArrow, isCtrlShiftZ, isCtrlZ, isDelete} from '../utils/keyboard'
import {isArrow, isCtrlShiftZ, isCtrlZ} from '../utils/keyboard'

function useEventHandlers() {
  const { canvas, setActiveObject, activeObject, setZoomRatio } = useCanvasContext()

  /**
   * Canvas Mouse wheel handler
   */

  const onMouseWheel = useCallback(
    event => {
      if (canvas && event.e.ctrlKey) {
        const delta = event.e.deltaY
        let zoomRatio = canvas.getZoom()
        if (delta > 0) {
          // zoomRatio -= 0.04
        } else {
          // zoomRatio += 0.04
        }
        setZoomRatio(zoomRatio)
      }
      event.e.preventDefault()
      event.e.stopPropagation()
    },
    [canvas]
  )

  useEffect(() => {
    if (canvas) {
      canvas.on('mouse:wheel', onMouseWheel)
    }
    return () => {
      if (canvas) {
        canvas.off('mouse:wheel', onMouseWheel)
      }
    }
  }, [canvas])

  /**
   * Canvas selection handlers
   */

  const onSelect = useCallback(
    ({ target }) => {
      if (target) {
        if (canvas) {
          setActiveObject(canvas.getActiveObject())
        }
      } else {
        setActiveObject(null)
      }
    },
    [canvas]
  )

  useEffect(() => {
    if (canvas) {
      canvas.on('selection:created', onSelect)
      canvas.on('selection:cleared', onSelect)
      canvas.on('selection:updated', onSelect)
    }
    return () => {
      if (canvas) {
        canvas.off('selection:cleared', onSelect)
        canvas.off('selection:created', onSelect)
        canvas.off('selection:updated', onSelect)
      }
    }
  }, [canvas])

  /**
   * Keyboard Events Handler
   */

  const undo = useCallback(() => {
    // @ts-ignore
    canvas?.undo()
  }, [canvas])

  const redo = useCallback(() => {
    // @ts-ignore
    canvas?.redo()
  }, [canvas])

  const moveUp = useCallback(() => {
    if (activeObject && canvas) {
      activeObject.top = activeObject.top - 2
      activeObject.setCoords()
      canvas.requestRenderAll()
    }
  }, [activeObject, canvas])

  const moveDown = useCallback(() => {
    if (activeObject && canvas) {
      activeObject.top = activeObject.top + 2
      activeObject.setCoords()
      canvas.requestRenderAll()
    }
  }, [activeObject, canvas])

  const moveRight = useCallback(() => {
    if (activeObject && canvas) {
      activeObject.left = activeObject.left + 2
      activeObject.setCoords()
      canvas.requestRenderAll()
    }
  }, [activeObject, canvas])

  const moveLeft = useCallback(() => {
    if (activeObject && canvas) {
      activeObject.left = activeObject.left - 2
      activeObject.setCoords()
      canvas.requestRenderAll()
    }
  }, [activeObject, canvas])

  // const deleteObj = useCallback(() => {
  //   if (activeObject && canvas) {
  //
  //     if (activeObject.type === 'activeSelection') {
  //       const group = activeObject;
  //       // @ts-ignore
  //       group.forEachObject((obj) => {
  //         canvas.remove(obj);
  //       });
  //       canvas.discardActiveObject();
  //     } else {
  //       canvas.remove(activeObject);
  //     }
  //
  //     canvas.requestRenderAll();
  //   }
  // }, [activeObject, canvas])

    let clipboard = null; // 클립보드 변수 추가

    // Ctrl+C 이벤트 핸들러
    const copyObject = useCallback(() => {
        if (activeObject && canvas) {
            return new Promise((resolve) => {
                canvas.getActiveObject().clone(cloned => {
                    clipboard = cloned;
                    resolve(cloned);
                });
            });
        }
        return Promise.resolve(null);
    }, [canvas, activeObject]);

    // Ctrl+V 이벤트 핸들러
    const pasteObject = useCallback(async () => {
        const clonedObj = await copyObject();
        if (clonedObj && canvas) {
            // @ts-ignore
            clonedObj.clone(cloned => {
                canvas.discardActiveObject();
                cloned.set({
                    left: cloned.left + 10,
                    top: cloned.top + 10,
                    evented: true,
                });
                if (cloned.type === 'activeSelection') {
                    // 객체가 그룹인 경우 모든 객체를 캔버스에 추가
                    cloned.canvas = canvas;
                    cloned.forEachObject(obj => {
                        canvas.add(obj);
                    });
                    cloned.setCoords();
                } else {
                    // 객체가 그룹이 아닌 경우 캔버스에 단순히 추가
                    canvas.add(cloned);
                }
                //@ts-ignore
                clipboard = cloned;
                canvas.setActiveObject(cloned);
                canvas.requestRenderAll();
            });
        }
    }, [canvas, copyObject]);

    const onKeyDown = useCallback(
        e => {
            // isCtrlZ(e) && undo()
            // isCtrlShiftZ(e) && redo()
            if (isArrow(e)) {
                e.code === 'ArrowLeft' && moveLeft()
                e.code === 'ArrowRight' && moveRight()
                e.code === 'ArrowDown' && moveDown()
                e.code === 'ArrowUp' && moveUp()
            }
            // isDelete(e) && deleteObj()
            if (e.ctrlKey) {
                e.code === 'KeyC' && copyObject();
                e.code === 'KeyV' && pasteObject();
            }
        },
        [canvas, activeObject]
    )
    useEffect(() => {
        document.addEventListener('keydown', onKeyDown)
        return () => {
            document.removeEventListener('keydown', onKeyDown)
        }
    }, [canvas, activeObject])
}

export default useEventHandlers
