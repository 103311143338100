import { useEffect, useState } from 'react'
import axios from 'axios'
import { fabric } from 'fabric'
import { useCanvasContext } from '@components/Canvas/hooks'


function ImagesPanel() {
  const [images, setImages] = useState<any[]>([])
  const { canvas } = useCanvasContext()

  const imagesLeader = () => {
    axios.get(`/images/list.json`)
      .then((response: any) => setImages(JSON.parse(response.request.response)))
      .catch(error => console.log(error));

  }

  useEffect(imagesLeader, [])

  const renderItems = () => {
    return images.map(obj => {

      let label_top;
      let label_down;

      if (obj.includes("과정평가형")) {
        label_top = "과정평가형";
      } else if (obj.includes("국가기술자격")) {
        label_top = "국가기술자격";
      } else if (obj.includes("일학습병행")) {
        label_top = "일학습병행";
      }

      if (obj.includes("기술사")) {
        label_down = "(기술사)";
      } else if (obj.includes("기능장")) {
        label_down = "(기능장)";
      } else if (obj.includes("기사")) {
        label_down = "(기사)";
      } else if (obj.includes("산업기사")) {
        label_down = "(산업기사)";
      } else if (obj.includes("기능사")) {
        label_down = "(기능사)";
      }

      return (
        <div className="image-item-container" onClick={() => downloadImage(obj)} key={obj}>ㅜ
          <img className="image-item" src={obj} />
          <span style={{ color: 'white', fontSize: '13px' }}>{label_top}</span>
          <span style={{ color: 'white', fontSize: '13px' }}>{label_down}</span>
        </div>
      )
    })
  }
  const downloadImage = obj => {
      const url = obj;
        fabric.loadSVGFromURL(url, (objects, options) => {
          var object = fabric.util.groupSVGElements(objects, {
            options,
            id:'imageItem',
            url: url
          })
          //@ts-ignore
          const workarea = canvas.getObjects().find(obj => obj.id === 'workarea')
          canvas.clear()
          canvas.add(workarea)
          canvas.add(object)
          object.scaleToHeight(600)
          object.center()
          object.clipPath = workarea
          object.selectable = false
          canvas.renderAll()
        })
  }

  return (
    <>
      <div style={{ padding: '0 2rem' }} className="images-list">
        {renderItems()}
      </div>
    </>
  )
}
export default ImagesPanel
