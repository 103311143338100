import { useEffect, useState } from 'react'
import { Input, InputGroup, InputLeftElement } from '@chakra-ui/react'
import { SearchIcon } from '@chakra-ui/icons'
import { getImage, getImages } from '@services/iconscout'
import { useCanvasContext } from '@components/Canvas/hooks'
import { useDebounce } from 'use-debounce'

import { fabric } from 'fabric'
import axios from "axios";

function ObjectsPanel() {
  const [search, setSearch] = useState('')
  const [objects, setObjects] = useState<any[]>([])
  const [value] = useDebounce(search, 1000)
  const { canvas } = useCanvasContext()
  const [icons, setIcons] = useState<any[]>([])

  // useEffect(() => {
  //   getImages('love')
  //     .then((data: any) => setObjects(data))
  //     .catch(console.log)
  // }, [])

  const imagesLeader = () => {
    axios.get(`/icons/list.json`)
        .then((response: any) => setIcons(JSON.parse(response.request.response)))
        .catch(error => console.log(error));

  }

  useEffect(imagesLeader, [])

  // useEffect(() => {
  //   if (value) {
  //     getImages(value)
  //       .then((data: any) => setObjects(data))
  //       .catch(console.log)
  //   }
  // }, [value])

  const renderItems = () => {
    return icons.map(obj => {

      let label;

      if (obj.includes("과정평가형")) {
        label = "과정평가형";
      } else if (obj.includes("국가기술자격")) {
        label = "국가기술자격";
      } else if (obj.includes("일학습병행")) {
        label = "일학습병행";
      }

      return (
        <div className="object-item-container" style={{ width: '110px', display: 'flex', flexDirection: 'column', alignItems: 'center' }} onClick={() => downloadIcon(obj)} key={obj}>
          <img className="object-item" src={obj} />
          <span style={{ color: 'white', fontSize: '13px' }}>{label}</span>
        </div>
      )
    })
  }
  const downloadIcon = obj => {
    const url = obj;
      fabric.loadSVGFromURL(url, (objects, options) => {
        const object = fabric.util.groupSVGElements(objects, options)
        //@ts-ignore
        const workarea = canvas.getObjects().find(obj => obj.id === 'workarea')
        canvas.add(object)
        if(url.includes('/icons/hrdk')) {
          object.scaleToHeight(50)
        }else {
          object.scaleToHeight(300)
        }
        object.center()
        object.clipPath = workarea
        canvas.bringToFront(object);
        canvas.renderAll()
      })
  }

  return (
    <>
      {/*<div style={{ padding: '1rem 2rem' }}>*/}
      {/*  <InputGroup>*/}
      {/*    <InputLeftElement pointerEvents="none" children={<SearchIcon color="gray.300" />} />*/}
      {/*    <Input*/}
      {/*      onChange={e => setSearch(e.target.value)}*/}
      {/*      style={{ background: '#fff' }}*/}
      {/*      type="tel"*/}
      {/*      placeholder="Search objects"*/}
      {/*    />*/}
      {/*  </InputGroup>*/}
      {/*</div>*/}
      <div style={{ padding: '0 2rem' }} className="objects-list">
        {renderItems()}
      </div>
    </>
  )
}
export default ObjectsPanel
