import './FooterMenu.scss'
import {DownloadPNGIcon} from "@components/Editor/Navbar/NavbarIcons";
import {fabric} from "fabric";
import {useCanvasContext} from "@components/Canvas/hooks";

function FooterMenu() {

    const { canvas } = useCanvasContext()

    function handlePNGDownloadClick() {

        //@ts-ignore
        canvas.getObjects().find(obj => obj.id === 'workarea').set({fill: 'transparent'})
        canvas.renderAll()

        // 복사할 캔버스 생성자
        const canvasTmp = new fabric.Canvas(null);

        // 기존 캔버스의 크기를 복사한 캔버스에 설정
        canvasTmp.setWidth(canvas.getWidth());
        canvasTmp.setHeight(canvas.getHeight());

        // 기존 캔버스의 내용을 복사한 캔버스로 복사
        canvasTmp.loadFromJSON(canvas.toJSON(), canvasTmp.renderAll.bind(canvasTmp));

        //@ts-ignore
        canvasTmp.remove(canvasTmp.getObjects().find(obj => obj.type === 'rect'))
        canvasTmp.renderAll()

        // 현재 날짜를 가져오는 함수
        // @ts-ignore
        function getCurrentDate() {
            const today = new Date();
            const year = today.getFullYear();
            const month = (today.getMonth() + 1).toString().padStart(2, '0');
            const day = today.getDate().toString().padStart(2, '0');

            return `${year}${month}${day}`;
        }

        const currentDate = getCurrentDate();
        const fileName = `${currentDate}_badge.png`;

        // 렌더링 확인 이후 대기 시간 추가
        setTimeout(() => {
            // @ts-ignore
            const dataURL = canvasTmp.toDataURL('image/png');

            // 다운로드 링크를 생성하고 클릭
            const a = document.createElement('a');
            a.href = dataURL;
            a.download = fileName;
            a.click();
        }, 1000); // 1초 대기

        //@ts-ignore
        canvas.getObjects().find(obj => obj.id === 'workarea').set({fill: '#ffffff'})
        canvas.renderAll()
    }

  return (
    <div className="footermenu">
      {/*<div><img src="/logo1.png" alt="DXWORKS.INC" className="footermenu-logo" />Digital Badge Maker v1.0.0</div>*/}
      <div className="footermenu-text">Digital Badge Maker v1.0.0</div>
        <div className="footermenu-png" onClick={handlePNGDownloadClick}>
            <DownloadPNGIcon/>
        </div>
    </div>
  )
}

export default FooterMenu
