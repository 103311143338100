import i18n from 'i18next'
// import LanguageDetector from "i18next-browser-languagedetector"
import enLocale from './locales/en-EN.json'
import koLocale from './locales/ko-KR.json'

const i18nClient = i18n.init({
  load: 'all',
  whitelist: ['en', 'en-US', 'ko', 'ko-KR'],
  lng: 'ko',
  //   nonExplicitWhitelist: false,
  resources: {
    en: {
      translation: enLocale,
    },
    ko: {
      translation: koLocale,
    },
  },
})

export default i18nClient
