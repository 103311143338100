export const tabItems = [
  // {
  //   icon: 'templates',
  //   label: 'Şablonlar',
  //   name: 'templates',
  // },
  {
    icon: 'images',
    label: 'Fotoğraf',
    // name: 'images',
    name: 'backgroundimages',
  },
  {
    icon: 'objects',
    label: 'Nesneler',
    name: 'objects',
  },
  {
    icon: 'texts',
    label: 'Metin',
    name: 'text',
  },
  // {
  //   icon: 'borderText',
  //   label: 'Nesneler',
  //   name: 'borderText',
  // },
  // {
  //   icon: 'layers',
  //   label: 'Metin',
  //   name: 'layers',
  // }
]
