import { useCanvasContext } from '@components/Canvas/hooks'
import { useCallback } from 'react'
import { CanvasObjects } from '@components/Canvas'
import { propertiesToInclude } from '../constants/contants'
import { fabric } from "fabric";

function useCoreHandler() {
  const { canvas, activeObject } = useCanvasContext()

  // Add objects to canvas
  const addObject = useCallback(
    options => {
      const { type, ...textOptions } = options
      const element = CanvasObjects[type].render(textOptions)
      //@ts-ignore
      const workarea = canvas.getObjects().find(obj => obj.id === 'workarea')
      canvas.add(element)
      element.center()

      element.clipPath = workarea
      canvas.renderAll()
    },
    [canvas]
  )

  // Update properties, optional set metadata if present
  const setProperty = useCallback(
    (property, value) => {
      if (activeObject) {
        activeObject.set(property, value)
        activeObject.setCoords()
        canvas.requestRenderAll()
      }
    },
    [activeObject, canvas]
  )

  const exportJSON = useCallback(() => {
    const json = canvas.toJSON(propertiesToInclude)
    return json
  }, [canvas])

  const loadJSON = useCallback(
    json => {
      if (canvas) {
        canvas.loadFromJSON(json, () => {
          canvas.requestRenderAll()
        })
      }
    },
    [canvas]
  )

  const setCanvasBackgroundColor = useCallback(
    color => {
      // @ts-ignore
      const workarea = canvas.getObjects().find(object => object.id === 'workarea')
      if (workarea) {
        workarea.set('fill', color)
        canvas.requestRenderAll()
      }
    },
    [canvas]
  )

  // x2chi
  const setCanvasBackgroundImageColor = useCallback(
    color => {

        if (canvas.getActiveObject()) {

            // @ts-ignore
            if(canvas.getActiveObject()._objects !== undefined) {
                // @ts-ignore
                canvas.getActiveObject()._objects.forEach((obj) => {
                    obj.set('fill', color)
                });
            }else {
                canvas.getActiveObject().set('fill', color)
            }

            canvas.getActiveObject().setCoords()
            canvas.requestRenderAll()
        }else {
            // @ts-ignore
            const imageItem = canvas.getObjects().find(object => object.id === 'imageItem' )

            if (imageItem) {

                // @ts-ignore
                imageItem._objects.forEach((obj) => {
                    obj.set('fill', color)
                });

                canvas.requestRenderAll()
            }
        }

    },[canvas]
  )

  const setCanvaTextColor = useCallback(
    color => {

        if (canvas.getActiveObject()) {
            // @ts-ignore
            canvas.getActiveObject().set('fill', color)
            canvas.getActiveObject().setCoords()

            canvas.requestRenderAll()
        }

    },[canvas]
  )

  const setTextBold = useCallback(
    () => {

        if (canvas.getActiveObject()) {

            // @ts-ignore
            if (canvas.getActiveObject().get('fontWeight') === 'bold'){
                // @ts-ignore
                canvas.getActiveObject().set('fontWeight', 'normal')
                canvas.getActiveObject().setCoords()
                canvas.requestRenderAll()
            }else {
                // @ts-ignore
                canvas.getActiveObject().set('fontWeight', 'bold')
                canvas.getActiveObject().setCoords()
                canvas.requestRenderAll()
            }
        }

    },[canvas]
  )

    const deleteObj = useCallback(() => {
        if (activeObject && canvas) {

            if (activeObject.type === 'activeSelection') {
                const group = activeObject;
                // @ts-ignore
                group.forEachObject((obj) => {
                    canvas.remove(obj);
                });
                canvas.discardActiveObject();
            } else {
                canvas.remove(activeObject);
            }

            canvas.requestRenderAll();
        }
    }, [activeObject, canvas])

  return { exportJSON, loadJSON, setCanvasBackgroundColor, addObject, setProperty, setCanvasBackgroundImageColor, setCanvaTextColor, setTextBold, deleteObj }
}

export default useCoreHandler
