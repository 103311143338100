// @ts-nocheck
import {useCanvasContext} from '@/components/Canvas/hooks'
import {CSSProperties, useState} from 'react'
import './ObjectsToolbox.scss'
import {useCoreHandler} from '@/components/Canvas/handlers'
import emptyColorPlaceholder from "@assets/images/base-color-picker.png";
import {SwatchesPicker} from "react-color";

function ObjectsTool() {

  return (
    <div className="editor-toolbox-container">
      <div className="editor-toolbox objects">
        {/*<div style={{ display: 'flex' }}>*/}
        {/*  <Popover placement="bottom-start" matchWidth={true}>*/}
        {/*    <PopoverTrigger>*/}
        {/*      <div className="font-family-selector">*/}
        {/*        <div>{options.fontFamily}</div>*/}
        {/*        <ChevronDownIcon />*/}
        {/*      </div>*/}
        {/*    </PopoverTrigger>*/}
        {/*    <PopoverContent style={{ width: '240px' }}>*/}
        {/*      <PopoverBody>*/}
        {/*        {fontsList.map(fontItem => (*/}
        {/*          <div*/}
        {/*            onClick={() => onChangeFontFamily(fontItem)}*/}
        {/*            style={{ fontFamily: fontItem }}*/}
        {/*            className="list-item"*/}
        {/*            key={fontItem}*/}
        {/*          >*/}
        {/*            {fontItem}*/}
        {/*          </div>*/}
        {/*        ))}*/}
        {/*      </PopoverBody>*/}
        {/*    </PopoverContent>*/}
        {/*  </Popover>*/}
        {/*  <Popover placement="bottom-start" matchWidth={true}>*/}
        {/*    <PopoverTrigger>*/}
        {/*      <div className="font-size-selector">*/}
        {/*        <div>{options.fontSize}</div>*/}
        {/*        <ChevronDownIcon />*/}
        {/*      </div>*/}
        {/*    </PopoverTrigger>*/}
        {/*    <PopoverContent style={{ width: '62px', height: '400px', overflowY: 'scroll'}}>*/}
        {/*      <PopoverBody>*/}
        {/*        {fontSizeList.map(fontSize => (*/}
        {/*          <div*/}
        {/*            onClick={() => onChangeFontSize(fontSize)}*/}
        {/*            // style={{ fontSize: fontSize }}*/}
        {/*            className="list-font-size"*/}
        {/*            key={fontSize}*/}
        {/*          >*/}
        {/*            {fontSize}*/}
        {/*          </div>*/}
        {/*        ))}*/}
        {/*      </PopoverBody>*/}
        {/*    </PopoverContent>*/}
        {/*  </Popover>*/}
        {/*</div>*/}
        {/*<div style={{marginRight: '-22px'}}>*/}
          <Toolbox />
        {/*</div>*/}
        {/*<div style={{paddingRight: '88%'}}>*/}
        {/*  <TextFontFunctionBox />*/}
        {/*</div>*/}
        {/*<div className="section-two">*/}
          {/*<OpacityIcon />*/}
          <DeleteIcon />
        {/*</div>*/}
      </div>
    </div>
  )
}

function VerticalSeparator() {
  return <div className="vertical-separator"></div>
}

function Toolbox() {

  const [dropdown, setDropdown] = useState({
    displayColorPicker: false,
  })
  const [options, setOptions] = useState({
    backgroundColor: '#ffffff',
  })
  const { setCanvasBackgroundImageColor } = useCoreHandler()

  const handleClick = () => {
    setDropdown({ ...dropdown, displayColorPicker: !dropdown.displayColorPicker })
  }
  const handleClose = () => {
    setDropdown({ ...dropdown, displayColorPicker: false })
  }

  const popover: CSSProperties = {
    position: 'absolute',
    zIndex: 2,
  }
  const cover: CSSProperties = {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  }

  const onColorChange = color => {

    //setCanvasBackgroundColor(color.hex)
    setCanvasBackgroundImageColor(color.hex)
    setOptions({ ...options, backgroundColor: color.hex })
  }
  return (
      <div className="editor-toolbox-container">
        <div className="editor-toolbox default">
          <div style={{ position: 'relative' }}>
            <div style={{ cursor: 'pointer' }} onClick={handleClick}>
              {options.backgroundColor === '#ffffff' ? (
                  <img
                      style={{ height: '30px', display: 'flex' }}
                      src={emptyColorPlaceholder}
                      alt="color picker"
                  />
              ) : (
                  <div style={{ background: options.backgroundColor }} className="editor-color-holder" />
              )}
            </div>

            {dropdown.displayColorPicker ? (
                <div style={popover}>
                  <div style={cover} onClick={handleClose} />
                  <SwatchesPicker color={options.backgroundColor} onChange={onColorChange} />
                </div>
            ) : null}
          </div>
          {/*<VerticalSeparator />*/}
        </div>
      </div>
  )
}

function DeleteIcon() {

  const { deleteObj } = useCoreHandler()

  const deleteActiveObject = () => {
    deleteObj()
  }

  return (
      <div style={{ cursor: 'pointer' }} onClick={deleteActiveObject}>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <path
              fill="currentColor"
              d="M8 5a3 3 0 0 1 3-3h2a3 3 0 0 1 3 3h4.25a.75.75 0 1 1 0 1.5H19V18a3 3 0 0 1-3 3H8a3 3 0 0 1-3-3V6.5H3.75a.75.75 0 0 1 0-1.5H8zM6.5 6.5V18c0 .83.67 1.5 1.5 1.5h8c.83 0 1.5-.67 1.5-1.5V6.5h-11zm3-1.5h5c0-.83-.67-1.5-1.5-1.5h-2c-.83 0-1.5.67-1.5 1.5zm-.25 4h1.5v8h-1.5V9zm4 0h1.5v8h-1.5V9z"
          ></path>
        </svg>
      </div>
  )
}

export default ObjectsTool
