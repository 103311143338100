// @ts-nocheck
import { ChevronDownIcon } from '@chakra-ui/icons'
import { useCanvasContext } from '@/components/Canvas/hooks'
import {CSSProperties, useEffect, useState} from 'react'
import { Popover, PopoverTrigger, PopoverContent, PopoverBody } from '@chakra-ui/react'
import './TextToolbox.scss'
import { useCoreHandler } from '@/components/Canvas/handlers'
import emptyColorPlaceholder from "@assets/images/base-color-picker.png";
import {SwatchesPicker} from "react-color";

const fontsList = [
  'Roboto Medium', 'Pretendard SemiBold', 'Pretendard ExtraBold', 'Godo Bold'
]
const fontSizeList = [2,4,6,8,10,12,14,16,18,20,22,24,26,28,30,32,34,36,38,40,42,44,46,48,50,52,54,56,58,60]

function TextTool() {
  const { activeObject } = useCanvasContext()
  const { setProperty } = useCoreHandler()
  const [options, setOptions] = useState({
    // fontFamily: 'Gothic A1',
    fontFamily: 'Roboto',
    fontSize: 1,
    fontWeight: 2,
    textAlign: 'center',
    textDecoration: 'none',
  })

  useEffect(() => {
    if (activeObject) {
      const updatedOptions = {
        fontFamily: activeObject.fontFamily,
        fontSize: activeObject.fontSize,
        fontWeight: activeObject.fontWeight,
        textAlign: activeObject.textAlign,
      }
      setOptions({ ...options, ...updatedOptions })
    }
  }, [activeObject])

  const onChangeFontFamily = fontFamily => {
    setProperty('fontFamily', fontFamily)
    setOptions({ ...options, fontFamily })
  }

  const onChangeFontSize = fontSize => {
    setProperty('fontSize', fontSize)
    setOptions({ ...options, fontSize })
  }

  return (
    <div className="editor-toolbox-container">
      <div className="editor-toolbox text">
        <div style={{ display: 'flex' }}>
          <Popover placement="bottom-start" matchWidth={true}>
            <PopoverTrigger>
              <div className="font-family-selector">
                <div>{options.fontFamily}</div>
                <ChevronDownIcon />
              </div>
            </PopoverTrigger>
            <PopoverContent style={{ width: '240px' }}>
              <PopoverBody>
                {fontsList.map(fontItem => (
                  <div
                    onClick={() => onChangeFontFamily(fontItem)}
                    style={{ fontFamily: fontItem }}
                    className="list-item"
                    key={fontItem}
                  >
                    {fontItem}
                  </div>
                ))}
              </PopoverBody>
            </PopoverContent>
          </Popover>
          <Popover placement="bottom-start" matchWidth={true}>
            <PopoverTrigger>
              <div className="font-size-selector">
                <div>{options.fontSize}</div>
                <ChevronDownIcon />
              </div>
            </PopoverTrigger>
            <PopoverContent style={{ width: '62px', height: '400px', overflowY: 'scroll'}}>
              <PopoverBody>
                {fontSizeList.map(fontSize => (
                  <div
                    onClick={() => onChangeFontSize(fontSize)}
                    // style={{ fontSize: fontSize }}
                    className="list-font-size"
                    key={fontSize}
                  >
                    {fontSize}
                  </div>
                ))}
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </div>
        {/*<div style={{marginRight: '-22px'}}>*/}
          <Toolbox />
        {/*</div>*/}
        {/*<div style={{paddingRight: '88%'}}>*/}
          <TextFontFunctionBox />
        {/*</div>*/}
        {/*<div className="section-two">*/}
          {/*<OpacityIcon />*/}
          <DeleteIcon />
        {/*</div>*/}
      </div>
    </div>
  )
}

function VerticalSeparator() {
  return <div className="vertical-separator"></div>
}

function Toolbox() {

  const [dropdown, setDropdown] = useState({
    displayColorPicker: false,
  })
  const [options, setOptions] = useState({
    backgroundColor: '#ffffff',
  })
  const { setCanvaTextColor } = useCoreHandler()

  const handleClick = () => {
    setDropdown({ ...dropdown, displayColorPicker: !dropdown.displayColorPicker })
  }
  const handleClose = () => {
    setDropdown({ ...dropdown, displayColorPicker: false })
  }

  const popover: CSSProperties = {
    position: 'absolute',
    zIndex: 2,
  }
  const cover: CSSProperties = {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  }

  const onColorChange = color => {

    setCanvaTextColor(color.hex)
    setOptions({ ...options, backgroundColor: color.hex })
  }
  return (
      <div className="editor-toolbox-container">
        <div className="editor-toolbox default">
          <div style={{ position: 'relative' }}>
            <div style={{ cursor: 'pointer' }} onClick={handleClick}>
              {options.backgroundColor === '#ffffff' ? (
                  <img
                      style={{ height: '30px', display: 'flex' }}
                      src={emptyColorPlaceholder}
                      alt="color picker"
                  />
              ) : (
                  <div style={{ background: options.backgroundColor }} className="editor-color-holder" />
              )}
            </div>

            {dropdown.displayColorPicker ? (
                <div style={popover}>
                  <div style={cover} onClick={handleClose} />
                  <SwatchesPicker color={options.backgroundColor} onChange={onColorChange} />
                </div>
            ) : null}
          </div>
          {/*<VerticalSeparator />*/}
        </div>
      </div>
  )
}

function TextFontFunctionBox() {

  const { setTextBold } = useCoreHandler()

  const onTextBold = () => {
    setTextBold()
  }

  return (
      <div className="editor-toolbox-container" style={{ cursor: 'pointer' }} onClick={onTextBold}>
        <div className="editor-toolbox default">
          <svg
              fill="#000000"
              height="27px"
              width="27px"
              viewBox="0 0 202 202"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
          >
            <path
                d="M148.004,94.812c18.332-8.126,28.671-23.362,28.671-42.752c0-17.261-6.954-31.206-20.11-40.328
          C145.653,4.166,130.438,0,113.721,0H16.957v34h17v134h-17v34h90.905c14.819,0,35.992-2.245,52.705-12.94
          c16.241-10.393,24.476-26.161,24.476-46.868C185.043,118.342,171.057,100.763,148.004,94.812z M103.12,80H73.957V34h26.096
          c25.961,0,36.551,6.34,36.551,21.884C136.604,75.816,118.396,80,103.12,80z M73.957,115h30.838c28.537,0,40.177,7.436,40.177,25.663
          c0,18.14-13.987,27.337-41.572,27.337H73.957V115z"
            />
          </svg>
          {/*<VerticalSeparator />*/}
        </div>
      </div>
  )

}

function OpacityIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g fill="currentColor" fillRule="evenodd">
        <path d="M3 2h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1zm0 8h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-2a1 1 0 0 1 1-1zm0 8h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-2a1 1 0 0 1 1-1z"></path>
        <path
          d="M11 2h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1zm0 8h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-2a1 1 0 0 1 1-1zm0 8h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-2a1 1 0 0 1 1-1z"
          opacity=".45"
        ></path>
        <path
          d="M19 2h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1zm0 8h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-2a1 1 0 0 1 1-1zm0 8h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-2a1 1 0 0 1 1-1z"
          opacity=".15"
        ></path>
        <path
          d="M7 6h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1zm0 8h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1v-2a1 1 0 0 1 1-1z"
          opacity=".7"
        ></path>
        <path
          d="M15 6h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1zm0 8h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-2a1 1 0 0 1 1-1z"
          opacity=".3"
        ></path>
      </g>
    </svg>
  )
}

function DeleteIcon() {

  const { deleteObj } = useCoreHandler()

  const deleteActiveObject = () => {
    deleteObj()
  }

  return (
      <div style={{ cursor: 'pointer' }} onClick={deleteActiveObject}>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <path
              fill="currentColor"
              d="M8 5a3 3 0 0 1 3-3h2a3 3 0 0 1 3 3h4.25a.75.75 0 1 1 0 1.5H19V18a3 3 0 0 1-3 3H8a3 3 0 0 1-3-3V6.5H3.75a.75.75 0 0 1 0-1.5H8zM6.5 6.5V18c0 .83.67 1.5 1.5 1.5h8c.83 0 1.5-.67 1.5-1.5V6.5h-11zm3-1.5h5c0-.83-.67-1.5-1.5-1.5h-2c-.83 0-1.5.67-1.5 1.5zm-.25 4h1.5v8h-1.5V9zm4 0h1.5v8h-1.5V9z"
          ></path>
        </svg>
      </div>
  )
}
export default TextTool
